import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import DetailsIcon from '@mui/icons-material/Details';
import { DataGrid } from '@mui/x-data-grid';

import LoadingBox from '../../utils/LoadingBox';
import { displayClientName } from '../../utils/Utils';
import { getBookings } from './BookingsSlice';

function Bookings () {
    const { isLoading, bookings } = useSelector((state) => state.bookings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        if(Object.keys(bookings).length === 0 && !dataFetched) {
            dispatch(getBookings());
            setDataFetched(true);
        }
    }, [bookings, dataFetched, dispatch]);

    const sortedBookings = sortBookings(bookings);

    const columns = [
        {field: 'status', headerName: 'Status', renderCell: (params) => (
            <div>
                {params.value === '3' && <IconButton aria-label='Completed'><CheckCircleOutlineIcon color='success' /></IconButton>}
                {params.value === '4' && <IconButton aria-label='Cancelled'><CancelIcon color='warning' /></IconButton>}
            </div>
        )},
        {field: 'reference', headerName: 'Reference'},
        {field: 'client', headerName: 'Client'},
        {field: 'vehicle', headerName: 'Car'},
        {field: 'from', headerName: 'From date'},
        {field: 'to', headerName: 'To date'},
        {field: 'value', headerName: 'Rental value'},
        {field: 'gallery', headerName: 'Images', type: 'actions', getActions: (params) => [
            <IconButton aria-label='Images' onClick={() => navigate(`/manage/bookings/${params.row.reference}/gallery`)} >
                <ImageSearchIcon color='info' />
            </IconButton>
        ]},
        {field: 'details', headerName: 'Details', type: 'actions', getActions: (params) => [
            <IconButton aria-label='Details' onClick={() => navigate(`/manage/bookings/${params.row.id}/details`)}>
                <DetailsIcon color='info' />
            </IconButton>
        ]}
    ];
    const rows = sortedBookings.map((booking) => (
        {
            id: booking.id,
            status: booking.status,
            reference: booking.reference,
            client: displayClientName(booking.client),
            vehicle: `${booking.vehicle.registration} ${booking.vehicle.model.vehicle_make} ${booking.vehicle.model.vehicle_model}`,
            from: moment(booking.pickup_datetime).format('D-MMM-YY'),
            to: moment(booking.dropoff_datetime).format('D-MMM-YY'),
            value: booking.booking_value
        }
    ));

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Bookings
            </Typography>
            <LoadingBox isLoading={isLoading} elements={bookings} noneText={'No bookings'} />
            <Paper sx={{padding: 2}}>
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.id || Math.random().toString(36).substring(2, 15)}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                />
            </Paper>
        </Container>
    )
}

export default Bookings;


export const sortBookings = (bookings) => {
    if (Object.keys(bookings).length === 0) {
        return []
    }
    return bookings.slice().sort((a, b) => {
        const dateA = new Date(a.pickup_datetime);
        const dateB = new Date(b.pickup_datetime);
        return dateA.getTime() - dateB.getTime();
    });
}