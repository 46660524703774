import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, Paper, Grid, Autocomplete, TextField, Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { DataGrid } from '@mui/x-data-grid';

import LoadingBox from '../../utils/LoadingBox'
import { getRates, updateRates, getSeasons, getBrackets } from './RatesSlice';
import { getModels } from '../fleet/FleetSlice';
import { toast } from 'react-toastify';

function Rates () {
    const { isLoading, rates, seasons, brackets } = useSelector((state) => state.rates);
    const { models } = useSelector((state) => state.fleet);
    const dispatch = useDispatch();
    const [dataFetched, setDataFetched] = useState(false);
    const [filterModel, setFilterModel] = useState(null);
    const [filterSeason, setFilterSeason] = useState(null);
    const [filterBracket, setFilterBracket] = useState(null);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [defaultRate, setDefaultRate] = useState(0);
    const [percentChange, setPercentChange] = useState(0);
    const [absChange, setAbsChange] = useState(0);

    useEffect(() => {
        if (Object.keys(rates).length === 0 && !dataFetched) {
            dispatch(getRates());
            setDataFetched(true);
        }
        if (Object.keys(seasons).length === 0) {
            dispatch(getSeasons());
        }
        if (Object.keys(brackets).length === 0) {
            dispatch(getBrackets());
        }
        if (Object.keys(models).length === 0) {
            dispatch(getModels());
        }
    }, [dispatch, rates, seasons, brackets, models, dataFetched])

    const filteredRates = Object.keys(rates).length > 0 ? filterRates(rates, filterModel, filterSeason, filterBracket) : [];

    const columns = [
        {field: 'vehicle', headerName: 'Vehicle model', width: 150},
        {field: 'defaultRate', headerName: 'Default rate', width: 150},
        {field: 'season', headerName: 'Season', width: 150},
        {field: 'bracket', headerName: 'Rental duration', width: 150},
        {field: 'dailyRate', headerName: 'Daily rate', width: 150}
    ]

    const rows = filteredRates.map((rate) => (
        {
            id: rate.id,
            vehicle: `${rate.vehicle_model.vehicle_make} ${rate.vehicle_model.vehicle_model}`,
            defaultRate: rate.default_rate,
            season: rate.season.name,
            bracket: `${rate.period_bracket.from_days} to ${rate.period_bracket.to_days} days`,
            dailyRate: rate.daily_rate
        }
    ))

    const validateUpdateRates = () => {
        let isValid = true;
        const parsedDefaultRate = parseFloat(defaultRate);
        const parsedPercentChange = parseFloat(percentChange);
        const parsedAbsChange = parseFloat(absChange);
        if (!isNaN(parsedDefaultRate) && parsedDefaultRate >= 0) {
            setDefaultRate(parsedDefaultRate.toFixed(2))
        } else {
            isValid = false;
            toast.error('Default rate not a number > 0')
            setDefaultRate(0)
        }
        if (!isNaN(parsedAbsChange)) {
            setAbsChange(parsedAbsChange.toFixed(2))
        } else {
            isValid = false;
            toast.error('Absolute change not a number')
            setAbsChange(0)
        }
        if (!isNaN(parsedPercentChange) && parsedPercentChange >= -1 && parsedPercentChange <= 1) {
            setPercentChange(parsedPercentChange.toFixed(2))
        } else {
            isValid = false;
            toast.error('Percent change not a valid number between -1 and 1')
            setPercentChange(0)
        }
        return isValid;
    }

    const getModelOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return `${option.vehicle_make} ${option.vehicle_model}`
    };
    const getSeasonOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return option.name
    }
    const getBracketOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return `${option.from_days} to ${option.to_days} days`
    };
    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        }
        return value.id === option.id;
    };

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Default Rental rates
            </Typography>
            <Grid container justify='flex-end' alignItems='center' direction='row-reverse'>
                <Grid item xs={6} md={3}>
                    <Autocomplete
                        value={filterBracket}
                        onChange={(event, newValue) => setFilterBracket(newValue)} 
                        options={Object.keys(brackets).length > 0 ? brackets : []}
                        getOptionLabel={getBracketOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        
                        renderInput={(params) => <TextField {...params} label='Duration' />}
                        sx={{marginRight: '10px', marginBottom: '10px'}}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Autocomplete
                        value={filterSeason}
                        onChange={(event, newValue) => setFilterSeason(newValue)} 
                        options={Object.keys(seasons).length > 0 ? seasons : []}
                        getOptionLabel={getSeasonOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        
                        renderInput={(params) => <TextField {...params} label='Season' />}
                        sx={{marginBottom: '10px'}}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Autocomplete
                        value={filterModel}
                        onChange={(event, newValue) => setFilterModel(newValue)} 
                        options={Object.keys(models).length > 0 ? models : []}
                        getOptionLabel={getModelOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        
                        renderInput={(params) => <TextField {...params} label='Model' />}
                        sx={{marginBottom: '10px'}}
                    />
                </Grid>
            </Grid>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '5px'}}>
                <Button onClick={() => setOpenUpdate(true)} type='button' endIcon={<UpdateIcon />} >Bulk update</Button>
            </Box>
            <Paper sx={{padding: 2}}>
                <LoadingBox isLoading={isLoading} elements={rates} noneText={'No rates'} />
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.id || Math.random().toString(36).substring(2, 15)}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                />
            </Paper>

            <Dialog open={openUpdate} onClose={() => setOpenUpdate(false)} PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    if (validateUpdateRates()) {
                        const updateData = {
                            model: filterModel,
                            season: filterSeason,
                            bracket: filterBracket,
                            default_rate: defaultRate,
                            percent_change: percentChange,
                            abs_change: absChange
                        }
                        dispatch(updateRates(updateData));
                        setOpenUpdate(false);
                    }
                }
            }}>
                <DialogTitle>Update rates</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{maringBottom: '5px'}}>
                        Provide update criteria
                    </DialogContentText>
                    <Grid container spacing={2} align='left' alignItems='center'>
                        <Grid item xs={12} md={4}>
                            <TextField label='Default rate' type='number' value={defaultRate} onChange={(e) => setDefaultRate(e.target.value)} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField label='Percent change' type='number' value={percentChange} onChange={(e) => setPercentChange(e.target.value)} />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField label='Absolute change' type='number' value={absChange} onChange={(e) => setAbsChange(e.target.value)} />
                        </Grid>
                    </Grid>
                    {(filterModel || filterSeason || filterBracket) ? (
                        <DialogContentText>
                        Changes will be applied to
                        {filterModel && <span> {filterModel.vehicle_make} {filterModel.vehicle_model}</span>}
                        {filterSeason && <span> Season: {filterSeason.name}</span>}
                        {filterBracket && (
                            <span> Bracket: {filterBracket.from_days} to {filterBracket.to_days} days</span>
                        )}
                        <br></br>
                        <span>If the entry does not yet exist, it will be created.</span>
                        </DialogContentText>
                    ) : (
                        <DialogContentText>Nothing selected. No updates will be made.</DialogContentText>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdate(false)} color='secondary'>Cancel</Button>
                    <Button type='submit' variant='contained'>Update</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Rates;

export const filterRates = (rates, filterModel, filterSeason, filterBracket) => {
    if (!filterModel && !filterSeason && !filterBracket) {
        return rates;
    }
    return rates.filter((rate) => {
        return (
            (!filterModel || rate.vehicle_model.id === filterModel.id) &&
            (!filterSeason || rate.season.id === filterSeason.id) &&
            (!filterBracket || rate.period_bracket.id === filterBracket.id)
        )
    })
}