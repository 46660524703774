import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Typography, Paper } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { toast } from 'react-toastify';

import MainMenu from '../menu/MainMenu';
import AccountForm from './AccountForm';
import { updateUser } from '../login/LoginSlice';
import { updatePassword } from './AccountSlice';


function Account () {
    const dispatch = useDispatch();
    const { isAuthenticated, isLoading, user } = useSelector((state) => state.auth);
    const [firstName, setFirstName] = useState(user.firstName);
    const [lastName, setLastName] = useState(user.lastName);
    const [username, setUsername] = useState(user.username);
    const [email, setEmail] = useState(user.email);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            firstName !== user.firstName ||
            lastName !== user.lastName ||
            username !== user.username ||
            email !== user.email
         ) {
            const userData = {
                username: username,
                first_name: firstName,
                last_name: lastName,
                email: email
            };
            dispatch(updateUser(userData));
         };
         if (password !== "" && oldPassword !== "" && password === confirmPassword) {
            const pwdData = {
                new_password: password,
                re_new_password: confirmPassword,
                current_password: oldPassword
            };
            dispatch(updatePassword(pwdData));
         } else {
            if (password !== confirmPassword) {
                toast.error('Password mismatch');
            };
            if (oldPassword === '' && password !== '') {
                toast.error('Current password not provided')
            }
         };
    }

    return (
        <Container>
            <MainMenu />
            <Typography variant='h3' align='center' gutterBottom>
                My account
            </Typography>
            <Paper sx={{padding: 2}}>
                <AccountForm 
                    onSubmit={handleSubmit}
                    onChangeFirstName={(e) => setFirstName(e.target.value)}
                    onChangeLastName={(e) => setLastName(e.target.value)}
                    onChangeUsername={(e) => setUsername(e.target.value)}
                    onChangeEmail={(e) => setEmail(e.target.value)}
                    onChangePassword={(e) => setPassword(e.target.value)}
                    onChangeConfirmPassword={(e) => setConfirmPassword(e.target.value)}
                    isAuthenticated={isAuthenticated}
                    onChangeOldPassword={(e) => setOldPassword(e.target.value)}
                    endIcon={<UpdateIcon />}
                    isLoading={isLoading}
                    buttonLabel={'Update'}
                    user = {{
                        firstName: firstName,
                        lastName: lastName,
                        username: username,
                        email: email
                    }}
                />
            </Paper>
        </Container>
    )
}

export default Account;