import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Typography, Paper, Grid, Box, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Button, Autocomplete, TextField, Divider, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DataGrid, gridClasses } from '@mui/x-data-grid';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';

import * as ApiCalls from './ApiCalls';
import LoadingBox from '../../utils/LoadingBox';
import { handleDecimalField } from '../../utils/Utils';
import { getPlan } from './PlannerSlice';
import { getOpsBooking, updateBooking } from '../bookings/BookingsSlice';
import CellContent from './CellContent';


function Planner () {
    const { isLoading, plan } = useSelector((state) => state.planner);
    const { opsBooking } = useSelector((state) => state.bookings);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [planRefresh, setPlanRefresh] = useState(true);
    const [fromDate, setFromDate] = useState(moment());
    const [toDate, setToDate] = useState(moment().add(14, 'days'));
    const [columns, setColumns] = useState([
        {field: 'registration', headerName: 'Vehicle', width: 200},
    ]);
    const [availableVehicles, setAvailableVehicles] = useState([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedVehicle, setSelectedVehicle] = useState(null);

    const [quote, setQuote] = useState(null);
    const [adhocBookingOpen, setAdhocBookingOpen] = useState(false);
    const [adhocStart, setAdhocStart] = useState(moment());
    const [adhocEnd, setAdhocEnd] = useState(moment().add(1, 'days'));
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [countryList, setCountryList] = useState([])
    const [residenceCountry, setResidenceCountry] = useState(null);
    const [insuranceList, setInsuranceList] = useState([]);
    const [insurance, setInsurance] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [paymentTerms, setPaymentTerms] = useState([]);
    const [selectedPaymentTerm, setSelectedPaymentTerm] = useState(null)

    const [vehicleToBlock, setVehicleToBlock] = useState(null);
    const [blockVehicleOpen, setBlockVehicleOpen] = useState(false);
    const [idToUnblock, setIdToUnblock] = useState(null);
    const [unblockVehicleOpen, setUnblockVehicleOpen] = useState(false);
    const [vehicleToUnblock, setVehicleToUnblock] = useState(null);

    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [paymentReference, setPaymentReference] = useState('');


    useEffect(() => {
        if (planRefresh) {
            dispatch(getPlan());
        }
        if (Object.keys(countryList).length === 0) {
            ApiCalls.getCountries(setCountryList);
        }
        if (Object.keys(insuranceList).length === 0) {
            ApiCalls.getInsurances(setInsuranceList, setInsurance);
        }
        return setPlanRefresh(false);
    }, [planRefresh, countryList, insuranceList, dispatch])

    const isBooked = (bookings, date) => {
        for (const booking of bookings) {
            const startDate = moment(booking.pickup_datetime);
            const endDate = moment(booking.dropoff_datetime);
            if (startDate <= date && endDate >= date) {
                return {
                    cellContent: `${booking.reference}-${date.format('DDMM')}`,
                    booking: booking
                }
            }
        }
        return false;
    }
    
    useEffect(() => {
        const dateColumns = [];
        const currentDate = moment(fromDate);
        while (currentDate.isSameOrBefore(toDate)) {
            const loopDate = moment(currentDate);
            dateColumns.push({
                field: loopDate.format('DD-MM'),
                headerName: loopDate.format('DD'),
                headerAlign: 'center',
                width: 75,
                valueGetter: (params) => {
                    const isVehicleBooked = isBooked(params.row.bookings, loopDate);
                    if (isVehicleBooked) {
                        return isVehicleBooked
                    }
                    return 'A'
                },
                sortable: false,
                renderCell: (params) => <CellContent params={params} />
            });
            currentDate.add(1, 'days');
        }
        setColumns([
            {field: 'registration', headerName: 'Vehicle', width: 200, valueGetter: (params) => {
                return `${params.row.registration} ${params.row.model.vehicle_make} ${params.row.model.vehicle_model}`
            }},
            ...dateColumns
        ]);

    }, [fromDate, toDate])

    useEffect(() => {
        if (selectedVehicle !== null) {
            ApiCalls.getQuotation(selectedVehicle.id, `${adhocStart.valueOf()}-${adhocEnd.valueOf()}`, insurance.id, setQuote)
        }
        ApiCalls.getAvailableVehicles('period', `${adhocStart.valueOf()}-${adhocEnd.valueOf()}`, setAvailableVehicles);
    }, [selectedVehicle, adhocStart, adhocEnd, insurance])

    useEffect(() => {
        if (Object.keys(paymentTerms).length === 0) {
            ApiCalls.getPaymentTerms(setPaymentTerms);
        }
        if (selectedPaymentTerm === null && Object.keys(paymentTerms).length > 0) {
            setSelectedPaymentTerm(paymentTerms.find((term) => term.percentage === '0.00')?.id || paymentTerms[0].id)
        }
    }, [paymentTerms, selectedPaymentTerm])

    const handleCellDoubleClick = (params) => {
        if (params.field !== 'registration' && params.value !== 'A') {
            const bookingReference = params.value.booking.reference
            const bookingId = params.value.booking.id
    
            if (bookingReference === 'BLOCKED') {
                setIdToUnblock(bookingId);
                setVehicleToUnblock(params.row);
                setUnblockVehicleOpen(true);
            } else {
                ApiCalls.getAvailableVehicles('booking', bookingReference, setAvailableVehicles);
                dispatch(getOpsBooking(bookingReference));
                setDialogOpen(true);
            }  
        } else {
            setVehicleToBlock(params.row);
            setBlockVehicleOpen(true);
        }
    }

    const getOptionLabel = (option) => {
        if (option === null || '') {
            return option;
        }
        return `${option.registration} ${option.model.vehicle_make} ${option.model.vehicle_model}`;
    }

    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        };
        return option.id === value.id;
    }

    const selectedVehicleError = selectedVehicle === null || selectedVehicle === ''
    const residenceCountryError = residenceCountry === null || residenceCountry === '';

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Planner
            </Typography>

            {isLoading || Object.keys(plan).length === 0 ? (
                <LoadingBox isLoading={isLoading} elements={plan} noneText={'No plan available'} />
            ) : (
                <Paper sx={{padding: 2}}>
                    <Grid container justify='flex-end' alignItems='center' direction='row-reverse'>
                        <Grid item xs={6} md={3}>
                            <DatePicker
                                label='To'
                                value={toDate}
                                minDate={moment().subtract(30, 'days')}
                                onChange={(newValue, context) => setToDate(moment(newValue))}
                            />
                        </Grid>
                        <Grid item xs={6} md={3}>
                            <DatePicker
                                label='From'
                                value={fromDate}
                                minDate={moment().subtract(30, 'days')}
                                onChange={(newValue, context) => setFromDate(moment(newValue))}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Box display='flex' justifyContent='flex-end'>
                                <Button onClick={() => setAdhocBookingOpen(true)} endIcon={<AddIcon />}>Adhoc booking</Button>
                            </Box>
                        </Grid>
                    </Grid>
                    <Box sx={{
                        marginTop: '10px',
                        [`.${gridClasses.cell}.booked`]: {
                            backgroundColor: '#3f51b5',
                            color: '#3f51b5'
                        },
                        [`.${gridClasses.cell}.available`]: {
                            backgroundColor: '#eceff1',
                            color: '#eceff1'
                        },
                        [`.${gridClasses.cell}.dispatched`]: {
                            backgroundColor: '#ffc107',
                            color: '#ffc107'
                        },
                        [`.${gridClasses.cell}.completed`]: {
                            backgroundColor: '#009688',
                            color: '#009688'
                        },
                        [`.${gridClasses.cell}.blocked`]: {
                            backgroundColor: '#9c27b0',
                            color: '#9c27b0'
                        },
                        [`.${gridClasses.cell}.pending`]: {
                            backgroundColor: '#f44336',
                            color: '#f44336'
                        }
                    }}>
                        <DataGrid 
                            rows={plan} 
                            columns={columns} 
                            getCellClassName={(params) => {
                                if (params.field === 'registration') {
                                    return ''
                                }
                                if (params.value === 'A') {
                                    return 'available'
                                }
                                if (params.value.booking.reference === 'BLOCKED') {
                                    return 'blocked';
                                }
                                if (parseInt(params.value.booking.percent_paid.slice(0, -1), 10) === 0) {
                                    return 'pending';
                                }
                                if (params.value.booking.status === '1') {
                                    return 'booked';
                                }
                                if (params.value.booking.status === '2') {
                                    return 'dispatched';
                                }
                                if (params.value.booking.status === '3') {
                                    return 'completed';
                                }
                            }}
                            disableColumnMenu
                            onCellDoubleClick={handleCellDoubleClick}
                        />
                    </Box>
                </Paper>
            )}
            {/* Re-assign vehicle dialog */}
            <Dialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const bookingData = {
                            id: opsBooking.id,
                            action: 'ops',
                            vehicle: selectedVehicle
                        }
                        dispatch(updateBooking(bookingData));
                        setDialogOpen(false);
                        setPlanRefresh(true);
                    }
                }}
            >
                <DialogTitle>Re-assign car for booking {opsBooking.reference}</DialogTitle>
                <DialogContent>
                    
                    <DialogContentText>Available cars</DialogContentText>
                    <Autocomplete
                        options={availableVehicles}
                        getOptionLabel={getOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        value={selectedVehicle}
                        onChange={(event, newValue) => setSelectedVehicle(newValue)}
                        renderInput={(params) => <TextField {...params} label='Cars' />}
                    />
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setDialogOpen(false)}>Cancel</Button>
                    <Button type='submit' variant='contained'>Update</Button>
                </DialogActions>
                <DialogContent>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Button onClick={() => navigate(`/manage/bookings/${'planner'}/${opsBooking.reference}/update`)} endIcon={<UpdateIcon />}>Update details</Button>
                        <Button
                            onClick={() => {
                                setDialogOpen(false);
                                setPaymentDialogOpen(true);
                            }}
                            endIcon={<AddIcon />}
                        >Payment</Button>
                    </Box>
                </DialogContent>
            </Dialog>
            {/* Create booking dialog */}
            <Dialog
                open={adhocBookingOpen}
                onClose={() => setAdhocBookingOpen(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const bookingData = {
                            action: 'book',
                            period: `${adhocStart.valueOf()}-${adhocEnd.valueOf()}`,
                            vehicle: selectedVehicle,
                            insurance: insurance,
                            first_name: firstName,
                            last_name: lastName,
                            email: email,
                            country: residenceCountry,
                            remarks: remarks,
                            payment_term: paymentTerms.find((term) => parseInt(term.id, 10) === parseInt(selectedPaymentTerm, 10))
                        }
                        ApiCalls.createBooking(bookingData, setPlanRefresh);
                        setAdhocBookingOpen(false);
                    }
                }}  
            >
                <DialogTitle>Create ad-hoc booking</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems='flex-end'>
                        <Grid item xs={12}>
                            <Typography variant='subtitle2' gutterBottom>
                                Quotation
                            </Typography>
                            <Typography variant='body2' gutterBottom>
                            {quote === null ? '' : (
                                `${quote.rental_period.days} days rental. Daily rate ${quote.daily_rate}. Total rental ${quote.total}`
                            )}
                            </Typography> 
                            <Divider/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label='Rental start'
                                value={adhocStart}
                                onChange={(newValue, context) => setAdhocStart(moment(newValue))}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label='Rental end'
                                value={adhocEnd}
                                onChange={(newValue, context) => setAdhocEnd(moment(newValue))}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                options={availableVehicles}
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={selectedVehicle}
                                onChange={(event, newValue) => setSelectedVehicle(newValue)}
                                renderInput={(params) => <TextField {...params} label='Available cars' error={selectedVehicleError} />}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                options={insuranceList}
                                getOptionLabel={(option) => option === null ? option : option.code}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={insurance}
                                onChange={(event, newValue) => setInsurance(newValue)}
                                renderInput={(params) => <TextField {...params} label='Insurance' />}
                                disableClearable={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField label='First name' required value={firstName} onChange={(e) => setFirstName(e.target.value)} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField label='Last name' required value={lastName} onChange={(e) => setLastName(e.target.value)} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField label='Email' required value={email} onChange={(e) => setEmail(e.target.value)} fullWidth />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                options={countryList}
                                getOptionLabel={(option) => option === null ? option : option.name}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={residenceCountry}
                                onChange={(event, newValue) => setResidenceCountry(newValue)}
                                renderInput={(params) => <TextField {...params} label='Country of residence' error={residenceCountryError} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label='Remarks' value={remarks} onChange={(e) => setRemarks(e.target.value)} multiline maxRows={4} fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            {quote !== null && Object.keys(quote.commission).length > 0 ? (
                                <div>
                                    <Typography variant='body2' gutterBottom>
                                        {`${quote.commission.currency}${quote.commission.amount} payable to secure booking.`}
                                    </Typography>
                                    <FormControl sx={{marginTop: '20px'}}>
                                        <FormLabel id='payment-terms'>Payment term</FormLabel>
                                        <RadioGroup
                                            aria-labelledby='payment-terms'
                                            value={selectedPaymentTerm === null ? 0 : selectedPaymentTerm}
                                            name='payment-terms-radios'
                                            onChange={(event) => setSelectedPaymentTerm(event.target.value)}
                                        >
                                            {paymentTerms.map((term) => (
                                                <FormControlLabel
                                                    key={term.id}
                                                    value={term.id}
                                                    control={<Radio />}
                                                    label={term.translations.en.description}
                                                />
                                            ))}
                                        </RadioGroup>
                                    </FormControl>
                                </div>  
                                ) : (<span></span>)
                            }
                            
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setAdhocBookingOpen(false)}>Cancel</Button>
                    <Button type='submit' variant='contained'>Create</Button>
                </DialogActions>
            </Dialog>
            {/* Block vehicle dialog */}
            <Dialog
                open={blockVehicleOpen}
                onClose={() => setBlockVehicleOpen(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const checkAvailability = availableVehicles.find((vehicle) => parseInt(vehicle.id, 10) === parseInt(vehicleToBlock.id, 10))
                        if (checkAvailability) {
                            const blockData = {
                                action: 'block',
                                vehicle: vehicleToBlock,
                                period: `${adhocStart.valueOf()}-${adhocEnd.valueOf()}`
                            }
                            ApiCalls.blockVehicle(blockData, setVehicleToBlock, setPlanRefresh);
                            setBlockVehicleOpen(false)
                        } else {
                            toast.error('Car booked in selected period');
                        }   
                    }
                }}
            >
                <DialogTitle>Block car {vehicleToBlock !== null ? `${vehicleToBlock.registration} ${vehicleToBlock.model.vehicle_make} ${vehicleToBlock.model.vehicle_model}` : ''}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} alignItems='flex-end'>
                        <Grid item xs={12}>
                            <DialogContentText>
                                Specify block out period
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label='From'
                                value={adhocStart}
                                onChange={(newValue, context) => setAdhocStart(moment(newValue))}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DateTimePicker
                                label='To'
                                value={adhocEnd}
                                onChange={(newValue, context) => setAdhocEnd(moment(newValue))}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setBlockVehicleOpen(false)}>Cancel</Button>
                    <Button type='submit' variant='contained'>Block</Button>
                </DialogActions>
            </Dialog>
            {/* Unblock vehicle dialog */}
            <Dialog
                open={unblockVehicleOpen}
                onClose={() => setUnblockVehicleOpen(false)}
            >
                <DialogTitle>
                    Unblock {vehicleToUnblock !== null ? `${vehicleToUnblock.registration} ${vehicleToUnblock.model.vehicle_make} ${vehicleToUnblock.model.vehicle_model}` : ''}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Unblock vehicle for selected period.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setUnblockVehicleOpen(false)}>Cancel</Button>
                    <Button variant='contained' onClick={() => {
                        ApiCalls.unblockVehicle(idToUnblock, setIdToUnblock, setPlanRefresh);
                        setUnblockVehicleOpen(false);
                    }}>Unblock</Button>
                </DialogActions>
            </Dialog>
            {/* Record payment dialog */}
            <Dialog
                open={paymentDialogOpen}
                onClose={() => setPaymentDialogOpen(false)}
                PaperProps={{
                    component: 'form',
                    onSubmit: (event) => {
                        event.preventDefault();
                        const paymentData = {
                            booking: opsBooking.id,
                            reference_number: paymentReference,
                            amount: paymentAmount,
                            currency: opsBooking.invoice_currency,
                            result: 'RAPP'
                        }
                        ApiCalls.createPayment(paymentData, setPlanRefresh);
                        setPaymentDialogOpen(false);
                    }
                }}
            >
                <DialogTitle>Record payment for booking {opsBooking.reference}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <DialogContentText>
                                Provide payment details
                            </DialogContentText>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label={`Amount in ${opsBooking.invoice_currency}`} required type='number' value={paymentAmount} onChange={(e) => handleDecimalField(e.target.value, setPaymentAmount)} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label='Payment reference' required value={paymentReference} onChange={(e) => setPaymentReference(e.target.value)} fullWidth />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button color='secondary' onClick={() => setPaymentDialogOpen(false)}>Cancel</Button>
                    <Button type='submit' variant='contained'>Submit</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Planner;