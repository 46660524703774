import axios from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { push } from "redux-first-history";
import { toast } from "react-toastify";

import { setAxiosAuthToken, toastOnError } from "../../utils/Utils";
import { getUserProfile, unsetUserProfile } from "../account/AccountSlice";


const initialState = {
    isAuthenticated: false,
    user: {},
    token: "",
    isLoading: false,
}

export const login = createAsyncThunk(
    'auth/login',
    async (userData, { dispatch, rejectWithValue }) => {
        try {
            const responseToken = await axios.post('/auth/token/login/', userData);
            dispatch(setToken(responseToken.data.auth_token));
            const responseUser = await axios.get('/auth/users/me/');
            const user = {
                username: responseUser.data.username,
                firstName: responseUser.data.first_name,
                lastName: responseUser.data.last_name,
                email: responseUser.data.email
            }
            dispatch(setCurrentUser(user));
            dispatch(getUserProfile(user));
            dispatch(push('/dashboard'));
            return responseUser.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
);


export const logout = createAsyncThunk(
    'auth/logout',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/auth/token/logout/');
            dispatch(unsetCurrentUser());
            dispatch(unsetUserProfile());
            dispatch(push('/'));
            toast.success(user.username + ' logged out');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateUser = createAsyncThunk(
    'user/update',
    async (userData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.put('auth/users/me/', userData);
            const user = {
                username: response.data.username,
                firstName: response.data.first_name,
                lastName: response.data.last_name,
                email: response.data.email
            }
            dispatch(setCurrentUser(user));
            toast.success('Account updated');
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setToken(state, action) {
            setAxiosAuthToken(action.payload);
            localStorage.setItem("token", action.payload);
            state.isAuthenticated = true;
            state.token = action.payload;
        },
        setCurrentUser(state, action) {
            localStorage.setItem("user", JSON.stringify(action.payload));
            state.user = action.payload;
        },
        unsetCurrentUser(state, action) {
            setAxiosAuthToken('');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            state.isAuthenticated = false;
            state.user = {};
            state.token = '';
        },
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true;
        }).addCase(login.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(login.rejected, (state, action) => {
            state.isLoading = false;
            toastOnError(action.error);
        }).addCase(logout.pending, (state) => {
            state.isLoading = true;
        }).addCase(logout.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(logout.rejected, (state, action) => {
            state.isLoading = false;
            toastOnError(action.error);
        }).addCase(updateUser.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateUser.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateUser.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { setToken, setCurrentUser, unsetCurrentUser } = loginSlice.actions;
export default loginSlice.reducer;
