import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardActions, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableRow, TextField, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UpdateIcon from '@mui/icons-material/Update';
import AddBoxIcon from '@mui/icons-material/AddBox';

import LoadingBox from '../../utils/LoadingBox';
import BookingSummary from '../bookings/BookingSummary';
import { getBooking, getDiscounts, deleteDiscount, updateDiscount, createDiscount } from './DiscountsSlice';

function Discounts () {
    const { isLoading, discounts, booking } = useSelector((state) => state.discounts);
    const { bookingRef } = useParams();
    const dispatch = useDispatch();
    const [prevDiscounts, setPrevDiscounts] = useState(discounts);
    const [dialog, setDialog] = useState(false);
    const [lineItem, setLineItem] = useState('');
    const [discount, setDiscount] = useState('');
    const [isNew, setIsNew] = useState(false);
    const [deleteDialog, setDeleteDialog] = useState(false);
    const [toEdit, setToEdit] = useState(null);
    const [toDelete, setToDelete] = useState(null);

    useEffect(() => {
        if (Object.keys(booking).length === 0 && Object.keys(discounts).length === 0) {
            dispatch(getBooking(bookingRef));
            dispatch(getDiscounts(bookingRef));
        }
        if (discounts !== prevDiscounts) {
            setPrevDiscounts(discounts);
            dispatch(getBooking(bookingRef));
        }
    }, [booking, discounts, prevDiscounts, bookingRef, dispatch]);

    const handleDelete = (discountId) => {
        setToDelete(discountId);
        setDeleteDialog(true);
    }

    const handleEdit = (discount) => {
        setIsNew(false);
        setToEdit(discount.id);
        setLineItem(discount.line_item);
        setDiscount(discount.discount)
        setDialog(true);
    }

    const handleAdd = () => {
        setIsNew(true);
        setLineItem('');
        setDiscount('');
        setDialog(true);
    }

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Discounts
            </Typography>
            <LoadingBox isLoading={isLoading} elements={booking} noneText={'No booking'} />
            {Object.keys(booking).length > 0 ? (
                <Card sx={{padding: 2}}>
                    <BookingSummary booking={booking} />
                    <CardActions>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2' gutterBottom>
                                    Applied discounts in {booking.invoice_currency}:
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Table>
                                    <TableBody>
                                        {Object.entries(discounts).map((discount) => (
                                            <TableRow key={discount[1].id}>
                                                <TableCell>{discount[1].line_item_label}</TableCell>
                                                <TableCell>{discount[1].discount}</TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleEdit(discount[1])}>
                                                        <EditIcon />
                                                    </IconButton>
                                                </TableCell>
                                                <TableCell>
                                                    <IconButton onClick={() => handleDelete(discount[1].id)}>
                                                        <DeleteForeverIcon />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Grid>
                            <Grid item xs={12} align='right'>
                                <Button onClick={() => handleAdd()} endIcon={<AddBoxIcon />}>Add discount</Button>
                            </Grid>
                        </Grid>
                    </CardActions>
                </Card>
            ) : null}
            <Dialog open={dialog} onClose={() => setDialog(false)} PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    if (isNew) {
                        const discountData = {
                            booking: {id: booking.id},
                            line_item: lineItem,
                            discount: discount
                        }
                        dispatch(createDiscount(discountData));
                    } else {
                        const discountData = {
                            id: toEdit,
                            booking: {id: booking.id},
                            line_item: lineItem,
                            discount: discount
                        }
                        dispatch(updateDiscount(discountData));
                    }
                    setDialog(false);
                }
            }}>
                <DialogTitle>
                    {discount === '' ? 'Add discount' : 'Edit discount'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Discount details
                    </DialogContentText>
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel id='id-line-item'>Line item</InputLabel>
                                <Select
                                    labelId='id-line-item'
                                    id='line-item'
                                    value={lineItem}
                                    label='Line item'
                                    onChange={(e) => setLineItem(e.target.value.toString())}
                                    required
                                >
                                    <MenuItem value={1}>Rental</MenuItem>
                                    <MenuItem value={2}>Insurance</MenuItem>
                                    <MenuItem value={3}>Cancellation Insurance</MenuItem>
                                    <MenuItem value={4}>Full Fuel Tank</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField type='number' value={discount} label='Discount' onChange={(e) => setDiscount(e.target.value)} required />
                        </Grid>
                    </Grid>                    
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialog(false)} color='secondary'>Cancel</Button>
                    {discount === '' ? (
                        <Button type='submit' variant='contained' endIcon={<AddBoxIcon />}>Apply</Button>
                    ) : (
                        <Button type='submit' variant='contained' endIcon={<UpdateIcon />}>Update</Button>
                    )}
                </DialogActions>
            </Dialog>
            <Dialog open={deleteDialog} onClose={() => setDeleteDialog(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>
                    Delete discount
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Confirm deletion. Action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(false)}>Cancel</Button>
                    <Button onClick={() => {
                        dispatch(deleteDiscount(toDelete));
                        setDeleteDialog(false)
                        }} 
                        variant='contained' 
                        color='warning'
                    >Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Discounts;