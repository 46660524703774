import { useRouteError } from "react-router-dom";
import { Box, Typography } from "@mui/material";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div id="error-page">
            <Box sx={{width: '100%', maxWidth: 500}}>
                <Typography variant="h3" gutterBottom>
                    Oops
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Sorry, an unexpected error has occurred.
                </Typography>
                <Typography variant="body2" gutterBottom>
                    {error.statusText || error.message}
                </Typography>
            </Box>
        </div>
    )
}