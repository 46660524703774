import React, {useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { Container, Box, List, ListItem, ListItemIcon, ListItemText, Drawer, IconButton, Divider, ListItemButton } from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import CarRentalIcon from '@mui/icons-material/CarRental';
import Groups2Icon from '@mui/icons-material/Groups2';
import CastIcon from '@mui/icons-material/Cast';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';

import { logout } from '../login/LoginSlice';

export default function Manage () {
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const openDrawer = () => {
        setOpen(true);
    };
    const closeDrawer = () => {
        setOpen(false);
    };

    return (
        <Container>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems:'center'}}>
                <Link to={'/'}>
                    <img src='/logo_blue.png' alt='logo' height={75}></img>
                </Link>
            </Box>
            <Drawer open={open} variant='permanent'>
                <Box sx={{width: open ? 250 :  50}} role='presentation' >
                    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                        <IconButton onClick={open ? closeDrawer :  openDrawer}>
                            { open ? <ChevronLeftIcon color='primary' fontSize='large' /> : <MenuOpenIcon color='primary' />}
                        </IconButton>
                    </Box>
                    <Divider />
                    <List>
                        <ListItem key='dashboard' disablePadding>
                            <ListItemButton onClick={() => navigate('/dashboard')}>
                                <ListItemIcon>
                                    <MenuIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='Dashboard' /> : null}
                            </ListItemButton>  
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem key='ops' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/ops')}>
                                <ListItemIcon>
                                    <CastIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='My operations' /> : null}
                            </ListItemButton> 
                        </ListItem>
                        <ListItem key='planner' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/planner')}>
                                <ListItemIcon>
                                    <CalendarMonthIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='My planner' /> : null}
                            </ListItemButton> 
                        </ListItem>
                        <ListItem key='bookings' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/bookings')}>
                                <ListItemIcon>
                                    <CollectionsBookmarkIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='My bookings' /> : null}
                            </ListItemButton> 
                        </ListItem>
                        <ListItem key='fleet' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/fleet')}>
                                <ListItemIcon>
                                    <CarRentalIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='My cars' /> : null}
                            </ListItemButton> 
                        </ListItem>
                        <ListItem key='team' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/team')}>
                                <ListItemIcon>
                                    <Groups2Icon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='My team' /> : null}
                            </ListItemButton> 
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem key='partnerRates' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/partner/rates')}>
                                <ListItemIcon>
                                    <PriceChangeOutlinedIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='My rental rates' /> : null}
                            </ListItemButton> 
                        </ListItem>
                        <ListItem key='defaultRates' disablePadding>
                            <ListItemButton onClick={() => navigate('/manage/admin/rates')}>
                                <ListItemIcon>
                                    <PriceCheckIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='Default Rental rates' /> : null}
                            </ListItemButton> 
                        </ListItem>
                    </List>
                    <Divider />
                    <List>
                        <ListItem key='logout' disablePadding>
                            <ListItemButton onClick={() => dispatch(logout(user))}>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                { open ? <ListItemText primary='Logout' /> : null}
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Box>
            </Drawer>
            <Outlet />     
        </Container>
    )
}