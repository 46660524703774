import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Typography, Box, Paper, Button, Autocomplete, TextField, Grid, Tooltip, IconButton, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import DiscountIcon from '@mui/icons-material/Discount';
import UpdateIcon from '@mui/icons-material/Update';

import Loading from '../../utils/Loading';
import MultiSelect from '../../utils/MultiSelect';
import { displayClientName, handleIntegerField } from '../../utils/Utils';
import { getOpsBooking, updateBooking } from './BookingsSlice';
import { getFleet } from '../fleet/FleetSlice';

function BookingUpdate () {
    const { isLoading, opsBooking, insurances, extras, countries } = useSelector((state) => state.bookings);
    const { fleet, locations } = useSelector((state) => state.fleet);
    const { bookingRef, sourceComponent } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [vehicle, setVehicle] = useState({});
    const [pickupLocation, setPickupLocation] = useState(null);
    const [pickupDateTime, setPickupDateTime] = useState(null);
    const [deliveryAddress, setDeliveryAddress] = useState('');
    const [dropoffLocation, setDropoffLocation] = useState(null);
    const [dropoffDateTime, setDropoffDateTime] = useState(null);
    const [collectionAddress, setCollectionAddress] = useState('');
    const [flightNumber, setFlightNumber] = useState('');
    const [insurance, setInsurance] = useState(opsBooking.insurance_waiver || null);
    const [newExtras, setNewExtras] = useState([]);
    const [babySeats, setBabySeats] = useState(0);
    const [boosterSeats, setBoosterSeats] = useState(0);
    const [mainDriverName, setMainDriverName] = useState('');
    const [mainDriverLicence, setMainDriverLicence] = useState('');
    const [mainDriverCountry, setMainDriverCountry] = useState(null);
    const [remarks, setRemarks] = useState('');
    const [status, setStatus] = useState('');

    useEffect(() => {
        let mounted = true;

        if (Object.keys(opsBooking).lenght === 0 || opsBooking.reference !== bookingRef) {
            dispatch(getOpsBooking(bookingRef));
        }
        if (Object.keys(fleet).length === 0) {
            dispatch(getFleet());
        };
        if (mounted && opsBooking.vehicle) {
            setVehicle(opsBooking.vehicle)
        };
        if (mounted && opsBooking.pickup_location) {
            setPickupLocation(opsBooking.pickup_location)
        }
        if (mounted && opsBooking.pickup_datetime) {
            setPickupDateTime(moment(opsBooking.pickup_datetime));
        }
        if (mounted && opsBooking.delivery_address) {
            setDeliveryAddress(opsBooking.delivery_address);
        }
        if (mounted && opsBooking.dropoff_location) {
            setDropoffLocation(opsBooking.dropoff_location)
        }
        if (mounted && opsBooking.dropoff_datetime) {
            setDropoffDateTime(moment(opsBooking.dropoff_datetime));
        }
        if (mounted && opsBooking.collection_address) {
            setCollectionAddress(opsBooking.collection_address);
        }
        if (mounted && opsBooking.flight_number) {
            setFlightNumber(opsBooking.flight_number);
        }
        if (mounted && opsBooking.insurance_waiver) {
            setInsurance(opsBooking.insurance_waiver);
        }
        if (mounted && opsBooking.extras) {
            setNewExtras(opsBooking.extras);
        }
        if (mounted && opsBooking.baby_seats) {
            setBabySeats(opsBooking.baby_seats);
        }
        if (mounted && opsBooking.booster_seats) {
            setBoosterSeats(opsBooking.booster_seats);
        }
        if (mounted && opsBooking.main_driver_name) {
            setMainDriverName(opsBooking.main_driver_name);
        }
        if (mounted && opsBooking.main_driver_licence) {
            setMainDriverLicence(opsBooking.main_driver_licence);
        }
        if (mounted && opsBooking.main_driver_country) {
            setMainDriverCountry(opsBooking.main_driver_country);
        }
        if (mounted && opsBooking.remarks) {
            setRemarks(opsBooking.remarks)
        }
        if (mounted && opsBooking.status) {
            setStatus(opsBooking.status);
        }
        return () => mounted = false;
    }, [opsBooking, fleet, bookingRef, dispatch]);

    const handleUpdate = () => {
        const pickupDateTimeString = pickupDateTime.toISOString().slice(0, 23).replace('.000', 'Z');
        const dropoffDateTimeString = dropoffDateTime.toISOString().slice(0, 23).replace('.000', 'Z');
        const bookingData = {
            id: opsBooking.id,
            action: 'ops'
        }
        if (opsBooking.vehicle !== vehicle || opsBooking.pickup_datetime !== pickupDateTimeString || opsBooking.dropoff_datetime !== dropoffDateTimeString) {
            bookingData.vehicle = vehicle;
            bookingData.pickup_datetime = pickupDateTimeString.replace('Z', '+00:00');
            bookingData.dropoff_datetime = dropoffDateTimeString.replace('Z', '+00:00');
        }
        if (pickupLocation !== 'Delivery' && opsBooking.pickup_location !== pickupLocation) {
            bookingData.pickup_location = pickupLocation;
        }
        if (opsBooking.delivery_address !== deliveryAddress) {
            bookingData.delivery_address = deliveryAddress;
        }
        if (dropoffLocation !== 'Collection' && opsBooking.dropoff_location !== dropoffLocation) {
            bookingData.dropoff_location = dropoffLocation;
        }
        if (opsBooking.collection_address !== collectionAddress) {
            bookingData.collection_address = collectionAddress;
        }
        if (opsBooking.flight_number !== flightNumber) {
            bookingData.flight_number = flightNumber;
        }
        if (opsBooking.insurance_waiver !== insurance) {
            bookingData.insurance_waiver = insurance;
        }
        if (opsBooking.extras !== newExtras) {
            bookingData.extras = newExtras;
        }
        if (opsBooking.baby_seats !== babySeats) {
            bookingData.baby_seats = babySeats;
        }
        if (opsBooking.booster_seats !== boosterSeats) {
            bookingData.booster_seats = boosterSeats;
        }
        if (opsBooking.main_driver_name !== mainDriverName && mainDriverName !== '') {
            bookingData.main_driver_name = mainDriverName;
        }
        if (opsBooking.main_driver_licence !== mainDriverLicence && mainDriverLicence !== '') {
            bookingData.main_driver_licence = mainDriverLicence;
        }
        if (opsBooking.main_driver_country !== mainDriverCountry && mainDriverCountry !== null) {
            bookingData.main_driver_country = mainDriverCountry;
        }
        if (opsBooking.remarks !== remarks) {
            bookingData.remarks = remarks;
        }
        if (opsBooking.status !== status) {
            bookingData.status = status;
        }
        if (Object.keys(bookingData).length > 1) {
            dispatch(updateBooking(bookingData, 'ops'));
            if (sourceComponent === 'operations') {
                navigate('/manage/ops');
            } else if (sourceComponent === 'planner') {
                navigate('/manage/planner');
            } else {
                console.error('Unrecognized source component')
                console.log(sourceComponent)
                navigate('/dashboard');
            }
        } else {
            toast.info('No changes');
        }
    }

    const handleNewExtras = (event) => {
        const {target: {value}, } = event;
        setNewExtras(typeof value === 'string' ? value.split(',') : value);
    }

    //Autocomplete settings
    const getVehicleOptionLabel = (option) => {
        if (option === null) {
            return option;
        }
        return `${option.registration} ${option.model?.vehicle_make} ${option.model?.vehicle_model}`
    };
    const getLocationOptionLabel = (option) => {
        if (option === '' || option === null) {
            return '';
        }
        return option.location
    };
    const getCountryOptionLabel = (option) => {
        if (option == null) {
            return option
        }
        return option.name
    };
    const isOptionEqualtoValue = (option, value) => {
        if (!option || !value) {
            return option === value;
        }
        return option.id === value.id;
    };
    
    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Update booking
            </Typography>
            <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
                {isLoading ? <Loading /> : null}
            </Box>
            {!isLoading && Object.keys(opsBooking).length > 0 && Object.keys(fleet).length > 0 ? (
                <Container>
                    {/* Header */}
                    <Paper sx={{padding: 2, margin: 2}}>
                        <Typography variant='h6' gutterBottom sx={{marginBottom: '20px'}}>
                            Booking {opsBooking.reference} | {displayClientName(opsBooking.client)}
                        </Typography>
                        <Grid container spacing={2} align='center'>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={fleet}
                                    value={vehicle}
                                    onChange={(e, newValue) => setVehicle(newValue)}
                                    renderInput={(params) => <TextField {...params} label='Assigned car' required />}
                                    getOptionLabel={getVehicleOptionLabel}
                                    isOptionEqualToValue={isOptionEqualtoValue}
                                    sx={{flexGrow: 1}}
                                    disableClearable
                                />
                            </Grid>
                            <Grid item xs={6} md={3} align='center'>
                                <FormControl>
                                    <InputLabel id='id-status'>Status</InputLabel>
                                    <Select
                                        labelId='id-status'
                                        value={status}
                                        label='Status'
                                        onChange={(e) => setStatus(e.target.value.toString())}
                                    >
                                        <MenuItem value={1}>Active</MenuItem>
                                        <MenuItem value={2}>Dispatched</MenuItem>
                                        <MenuItem value={3}>Completed</MenuItem>
                                        <MenuItem value={4}>Cancelled</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={3} align='right'>
                                <Tooltip title='Add discount'>
                                    <IconButton onClick={() => navigate(`/manage/bookings/${bookingRef}/discounts`)}>
                                        <DiscountIcon color='secondary' />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>                        
                    </Paper>
                    {/* Pickup and Dropoff details */}
                    <Paper sx={{padding: 2, margin: 2}}>
                        <Grid container spacing={2} align='left'>
                            <Grid item xs={12} align='left'>
                                <Typography variant='subtitle1' gutterBottom>
                                    Pick-up
                                </Typography>
                            </Grid>     
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={locations}
                                    value={pickupLocation}
                                    onChange={(e, newValue) => setPickupLocation(newValue)}
                                    renderInput={(params) => <TextField {...params} label='Pick-up location' />}
                                    getOptionLabel={getLocationOptionLabel}
                                    isOptionEqualToValue={isOptionEqualtoValue}
                                    sx={{flexGrow: 1}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    label='Pick-up date and time'
                                    value={pickupDateTime}
                                    onChange={(newValue, context) => setPickupDateTime(moment(newValue))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label='Delivery address' value={deliveryAddress} onChange={(e) => setDeliveryAddress(e.target.value)} fullWidth multiline maxRows={5} />
                            </Grid>
                            <Grid item xs={12} align='left'>
                                <Typography variant='subtitle1' gutterBottom>
                                    Drop-off
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Autocomplete
                                    options={locations}
                                    value={dropoffLocation}
                                    onChange={(e, newValue) => setDropoffLocation(newValue)}
                                    renderInput={(params) => <TextField {...params} label='Drop-off location' />}
                                    getOptionLabel={getLocationOptionLabel}
                                    isOptionEqualToValue={isOptionEqualtoValue}
                                    sx={{flexGrow: 1}}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <DateTimePicker
                                    label='Drop-off date and time'
                                    value={dropoffDateTime}
                                    onChange={(newValue, context) => setDropoffDateTime(moment(newValue))}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label='Collection address' value={collectionAddress} onChange={(e) => setCollectionAddress(e.target.value)} fullWidth multiline maxRows={5} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label='Flight details' value={flightNumber} onChange={(e) => setFlightNumber(e.target.value)} fullWidth multiline maxRows={3} />
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* Insurance and Extras */}
                    <Paper sx={{padding: 2, margin: 2}}>
                        <Grid container spacing={2} align='left' alignItems='center'>
                            <Grid item xs={12} align='left'>
                                <Typography variant='subtitle1' gutterBottom>
                                    Insurance and extras
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <FormControl fullWidth>
                                    <InputLabel id='id-insurance-waiver'>Insurance waiver</InputLabel>
                                    <Select
                                        labelId='id-insurance-waiver'
                                        id='insurance-waiver'
                                        value={insurances.find((waiver) => waiver.id === insurance.id)}
                                        label={insurance.code}
                                        onChange={(e) => setInsurance(e.target.value)}
                                    >
                                        {insurances.map((waiver) => (
                                            <MenuItem key={waiver.id} value={waiver}>
                                                {waiver.code}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MultiSelect
                                    inputLabel={'Extras'}
                                    values={extras}
                                    valuesState={newExtras}
                                    labelFunction={(value) => {return value.name}}
                                    onChange={handleNewExtras}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextField label='Baby seats' type='number' value={babySeats} onChange={(e) => handleIntegerField(e.target.value, setBabySeats)} sx={{marginBottom: 2}} />
                                <TextField label='Booster seats' type='number' value={boosterSeats} onChange={(e) => handleIntegerField(e.target.value, setBoosterSeats)} />
                            </Grid>
                        </Grid>
                    </Paper>
                    {/* Other items */}
                    <Paper sx={{padding: 2, margin: 2}}>
                        <Grid container spacing={2} align='left' alignItems='center'>
                            <Grid item xs={12} align='left'>
                                <Typography variant='subtitle1' gutterBottom>
                                    Other booking items
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label='Main driver name' value={mainDriverName} onChange={(e) => setMainDriverName(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextField label='Main driver licence' value={mainDriverLicence} onChange={(e) => setMainDriverLicence(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Autocomplete
                                    options={countries}
                                    value={mainDriverCountry}
                                    onChange={(e, newValue) => setMainDriverCountry(newValue)}
                                    getOptionLabel={getCountryOptionLabel}
                                    isOptionEqualToValue={isOptionEqualtoValue}
                                    renderInput={(params) => <TextField {...params} label='Main driver country' />}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label='Remarks' value={remarks} onChange={(e) => setRemarks(e.target.value)} fullWidth multiline maxRows={5} />
                            </Grid>
                        </Grid>
                    </Paper>
                    <Grid container spacing={2} align='left'>
                        <Grid item xs={12}>
                            <Button onClick={handleUpdate} variant='contained' endIcon={<UpdateIcon />}>Update</Button>
                        </Grid>
                    </Grid>
                </Container>
            ) : null}
        </Container>
    )
}

export default BookingUpdate;