import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Typography, Paper, Grid, Box, IconButton } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import LoadingBox from '../../utils/LoadingBox';
import { getGallery, deleteImage } from './BookingsSlice';

function BookingGallery () {
    const { isLoading, gallery } = useSelector((state) => state.bookings);
    const { bookingRef } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (Object.keys(gallery).length === 0) {
            dispatch(getGallery(bookingRef));
        }
    }, [gallery, bookingRef, dispatch])

    const handleDelete = (imageId) => {
        dispatch(deleteImage(imageId));
    }

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Images
            </Typography>
            <Typography variant='subtitle2' align='center' gutterBottom>
                Booking {bookingRef}
            </Typography>
            <LoadingBox isLoading={isLoading} elements={gallery} noneText={'No images'} />
            <Paper sx={{padding: 2}}>
                {!isLoading && Object.keys(gallery).length > 0 ? (
                    <Grid container spacing={2} align='center'>
                        {gallery.map((image) => (
                            <Grid item xs={6} md={3} key={image.id}>
                                <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <a href={image.image} target='_blank' rel='noreferrer'><img src={image.image} alt=''/></a>
                                </Box>
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <IconButton onClick={() => handleDelete(image.id)}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                ) : null}
            </Paper>
        </Container>
    )
}

export default BookingGallery;