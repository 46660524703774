import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Paper, Typography, Autocomplete, TextField, Grid, Button, Box, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'
import { List, ListItem, ListItemIcon, ListItemButton, ListItemText, IconButton } from '@mui/material'
import CarRentalIcon from '@mui/icons-material/CarRental';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import LoadingBox from '../../utils/LoadingBox';
import { getFleet, deleteVehicle } from './FleetSlice';

function Fleet () {
    const { isLoading, fleet, locations, models } = useSelector((state) => state.fleet);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataFetched, setDataFetched] = useState(false);
    const [filterLocations, setFilterLocations] = useState([{'id':0, 'location': 'All'}]);
    const [filterModels, setFilterModels] = useState([{'id':0, 'vehicle_make': 'All', 'vehicle_model': ''}]);
    const [filterLocation, setFilterLocation] = useState(filterLocations[0]);
    const [filterModel, setFilterModel] = useState(filterModels[0]);
    const [open, setOpen] = useState(false);
    const [carToDetele, setCarToDelete] = useState(null);

    useEffect(() => {
        if (Object.keys(fleet).length === 0 && !dataFetched) {
            dispatch(getFleet());
            setDataFetched(true);
        }
    }, [fleet, dataFetched, dispatch])
    
    useEffect(() => {
        if (!isLoading && locations.length > 0 && filterLocations.length === 1) {
            setFilterLocations([...filterLocations, ...locations]);
        }
        if (!isLoading && models.length > 0 && filterModels.length === 1) {
            setFilterModels([...filterModels, ...models]);
        }
    }, [isLoading, fleet, locations, models, filterLocations, filterModels])

    const filteredFleet = Object.keys(fleet).length > 0 ? filterFleet(fleet, filterLocation, filterModel) : [];

    const deleteCar = () => {
        dispatch(deleteVehicle(carToDetele));
        setOpen(false);
    }

    const handleClick = (vehicleId) => {
        navigate(`/manage/fleet/${vehicleId}/update`);
    };

    //Autocomplete settings
    const getLocationOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return option.location
    };
    const getModelOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return `${option.vehicle_make} ${option.vehicle_model}`
    };
    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        }
        return value.id === option.id;
    };
    
    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Cars
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={() => navigate('/manage/fleet/create')} type='button' endIcon={<AddIcon />}>Add car</Button>
            </Box>
            <Grid container justify='flex-end' alignItems='center' direction='row-reverse'>
                <Grid item xs={6} md={3}>
                    <Autocomplete
                        value={filterModel}
                        onChange={(event, newValue) => setFilterModel(newValue)} 
                        options={filterModels}
                        getOptionLabel={getModelOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderInput={(params) => <TextField {...params} label='Model' />}
                        sx={{marginRight: '10px', marginBottom: '10px'}}
                        disableClearable
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <Autocomplete 
                        value={filterLocation}
                        onChange={(event, newValue) => setFilterLocation(newValue)}
                        options={filterLocations}
                        getOptionLabel={getLocationOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderInput={(params) => <TextField {...params} label='Location' />}
                        sx={{marginBottom: '10px'}}
                        disableClearable
                    />                
                </Grid>
            </Grid>
            
            <Paper sx={{padding: 2}}>
                {isLoading || filteredFleet.length === 0 ? (
                    <LoadingBox isLoading={isLoading} elements={fleet} noneText={'No cars'} />
                ) : (
                    filteredFleet.map((vehicle) => (
                        <List>
                            <ListItem 
                                key={vehicle.id}
                                disablePadding
                                secondaryAction={
                                    <IconButton onClick={() => {setOpen(true); setCarToDelete(vehicle.id)}} edge='end'>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <ListItemButton onClick={() => handleClick(vehicle.id)} >
                                    <ListItemIcon>
                                        <CarRentalIcon color='primary' />
                                    </ListItemIcon>
                                    <ListItemText 
                                        primary={`${vehicle.registration} ${vehicle.model.vehicle_make} ${vehicle.model.vehicle_model}`} 
                                        secondary={vehicle.location.location}
                                    />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    ))
                )}
            </Paper>
            
            <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>
                    {'Delete car'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Confirm car deletion. Action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                    <Button onClick={deleteCar} color='warning'>Confirm</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default Fleet;


export const filterFleet = (fleet, filterLocation, filterModel) => {
    if ((!filterLocation && !filterModel) || (filterLocation.id === 0 && filterModel.id === 0)) {
        return fleet
    };
    return fleet.filter((vehicle) => {
        return (
            (!filterLocation || filterLocation.id === 0 || vehicle.location.id === filterLocation.id) &&
            (!filterModel || filterModel.id === 0 || vehicle.model.id === filterModel.id)
        )
    });
}