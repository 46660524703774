import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';


const initialState = {
    rates: {},
    seasons: {},
    brackets: {},
    partnerRates: {},
    isLoading: false
}


export const getRates = createAsyncThunk(
    'rates',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('admin/rates/');
            dispatch(setRates(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getPartnerRates = createAsyncThunk(
    'partnerRates',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('partner/rates/');
            dispatch(setPartnerRates(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createPartnerRate = createAsyncThunk(
    'createPartnerRate',
    async (rateData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('partner/rates/', rateData);
            dispatch(getPartnerRates());
            toast.success('Rate created');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const updatePartnerRate = createAsyncThunk(
    'updatePartnerRate',
    async (rateData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`partner/rates/${rateData.id}/`, rateData);
            dispatch(getPartnerRates());
            toast.success('Rate updated');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deletePartnerRate = createAsyncThunk(
    'deletePartnerRate',
    async (rateId, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.delete(`partner/rates/${rateId}/`);
            dispatch(getPartnerRates());
            toast.success('Rate deleted');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const updateRates = createAsyncThunk(
    'rates/update',
    async (updateData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('admin/rates/', updateData);
            dispatch(getRates());
            toast.success('Rates updated');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getSeasons = createAsyncThunk(
    'seasons',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('etc/seasons');
            dispatch(setSeasons(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getBrackets = createAsyncThunk(
    'brackets',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('etc/brackets');
            dispatch(setBrackets(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const ratesSlice = createSlice({
    name: 'rates',
    initialState,
    reducers: {
        setRates (state, action) {
            state.rates = action.payload;
        },
        setPartnerRates (state, action) {
            state.partnerRates = action.payload;
        },
        setSeasons (state, action) {
            state.seasons = action.payload;
        },
        setBrackets (state, action) {
            state.brackets = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRates.pending, (state) => {
            state.isLoading = true;
        }).addCase(getRates.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getRates.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getSeasons.pending, (state) => {
            state.isLoading = true;
        }).addCase(getSeasons.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getSeasons.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getPartnerRates.pending, (state) => {
            state.isLoading = true;
        }).addCase(getPartnerRates.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getPartnerRates.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setRates, setPartnerRates, setSeasons, setBrackets } = ratesSlice.actions;
export default ratesSlice.reducer;
