import React from 'react';
import { useSelector } from 'react-redux';
import { TextField, Button, Autocomplete, Grid } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import AddIcon from '@mui/icons-material/Add';

import Loading from '../../utils/Loading';

function FleetForm ({action, isLoading, vehicle, onRegistrationChange, onModelChange, onLocationChange, handleSubmit}) {
    const { locations, models } = useSelector((state) => state.fleet);

    //Autocomplete settings
    const getLocationOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return option.location
    };
    const getModelOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return `${option.vehicle_make} ${option.vehicle_model}`
    };
    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        }
        return value.id === option.id;
    };

    return (
        <form onSubmit={handleSubmit}>
            <Grid container spacing={2} align='center'>
                <Grid item xs={12} md={4}>
                    <TextField label='Registration' value={vehicle.registration} required onChange={(e) => onRegistrationChange(e.target.value)} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete 
                        value={vehicle.model}
                        onChange={(event, newValue) => onModelChange(newValue)}
                        options={models}
                        getOptionLabel={getModelOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderInput={(params) => <TextField {...params} label='Model' required />}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete 
                        value={vehicle.location}
                        onChange={(event, newValue) => onLocationChange(newValue)}
                        options={locations}
                        getOptionLabel={getLocationOptionLabel}
                        isOptionEqualToValue={isOptionEqualToValue}
                        renderInput={(params) => <TextField {...params} label='Location' required />}
                    />
                </Grid>
                <Grid item xs={12}>
                    {action === 'update' ? (
                        <Button type='submit' variant='contained' endIcon={<UpdateIcon />}>
                            {isLoading ? <Loading /> : 'Update'}
                        </Button>
                    ) : (
                        <Button type='submit' variant='contained' endIcon={<AddIcon />}>
                            {isLoading ? <Loading /> : 'Create'}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </form>
    )
}

export default FleetForm