import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Typography, Paper } from "@mui/material";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { createNewUser } from "./SignupSlice";
import MainMenu from "../menu/MainMenu";
import AccountForm from "../account/AccountForm";


function Signup () {
    const dispatch = useDispatch();
    const { isAuthenticated, isLoading } = useSelector((state) => state.auth);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    
    const handleSubmit = (event) => {
        event.preventDefault();
        if (password === confirmPassword && !isAuthenticated) {
            const userData = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                username: username,
                password: password,
                re_password: confirmPassword
            };
            dispatch(createNewUser(userData));
        } else {
            if (isAuthenticated) {
                toast.error('Already logged in');
            } else {
                toast.error('Password mismatch');
            }
        }
    }

    useEffect(() => {
        if (isAuthenticated && !toast.isActive()) {
            toast.info('Already registered');
        }
    }, [isAuthenticated]);

    return (
        <Container>
            <MainMenu />
            <Typography variant="h3" align="center" gutterBottom>
                Register
            </Typography>
            <Paper sx={{padding: 2}}>
                {!isAuthenticated ? (
                    <AccountForm 
                    onSubmit={handleSubmit}
                    onChangeFirstName={(e) => setFirstName(e.target.value)}
                    onChangeLastName={(e) => setLastName(e.target.value)}
                    onChangeUsername={(e) => setUsername(e.target.value)}
                    onChangeEmail={(e) => setEmail(e.target.value)}
                    onChangePassword={(e) => setPassword(e.target.value)}
                    onChangeConfirmPassword={(e) => setConfirmPassword(e.target.value)}
                    isAuthenticated={isAuthenticated}
                    endIcon={<AppRegistrationIcon />}
                    isLoading={isLoading}
                    buttonLabel={'Signup'}
                    user = {{
                        firstName: firstName,
                        lastName: lastName,
                        username: username,
                        email: email
                    }}
                />
                ) : null}
            </Paper>
            {!isAuthenticated ? (
                <Typography variant="body2" align="center" margin={2}>
                Already have an account? <Link to="/login">Login</Link>
                </Typography>
            ) : null}
        </Container>
    );
}

export default Signup;