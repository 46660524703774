import axios from 'axios';
import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, Paper } from '@mui/material';
import { Grid, TextField, Button, Autocomplete } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import { toast } from 'react-toastify';

import { getUserProfile, updateUserProfile } from './AccountSlice';
import { isEmpty } from '../../utils/Utils';
import MainMenu from '../menu/MainMenu';
import Loading from '../../utils/Loading';


function Profile () {
    const dispatch = useDispatch();
    const { profile, isLoading } = useSelector((state) => state.account);
    const [company, setCompany] = useState(profile.company || '');
    const [street,  setStreet] = useState(profile.street || '');
    const [zip, setZip] = useState(profile.zip_code || '');
    const [phone, setPhone] = useState(profile.phone || '');
    const [country, setCountry] = useState(profile.country || null);
    const [region, setRegion] = useState(profile.region || null);
    const [city, setCity] = useState(profile.city || null);
    const [countries, setCountries] = useState([]);
    const [regions, setRegions] = useState([region] || []);
    const [cities, setCities] = useState([city] || []);

    const getCountries = async () => {
        const response = await axios.get('/etc/countries/');
        setCountries(response.data);
    };
    const getRegions = async (id) => {
        const response = await axios.get(`/etc/regions?country=${id}`);
        setRegions(response.data);
    };
    const getCities = async (id) => {
        const response = await axios.get(`/etc/cities?region=${id}`);
        setCities(response.data);
    };

    useEffect(() => {  
        getCountries();
    },[]);

    useEffect(() => {
        if (isEmpty(profile)) {
            dispatch(getUserProfile(''));
        };
        if (!isEmpty(profile.country)) {
            getRegions(profile.country.id);
        } else {
            setRegions([profile.region] || null);
        };
        setRegions([profile.region] || null);
        setCities([profile.city] || null);
        setCompany(profile.company || '');
        setStreet(profile.street || '');
        setZip(profile.zip_code || '');
        setPhone(profile.phone || '');
        setCountry(profile.country || null);
        setRegion(profile.region || null);
        setCity(profile.city || null);
    }, [dispatch, profile]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            company !== profile.company ||
            street !== profile.street ||
            zip !== profile.zip_code ||
            phone !== profile.phone ||
            country !== profile.country ||
            region !== profile.region ||
            city !== profile.city
         ) {
            const profileData = {
                id: profile.id,
                company: company,
                street: street,
                zip_code: zip,
                phone: phone,
                country: country,
                region: region,
                city: city
            };
            dispatch(updateUserProfile(profileData));
         } else {
            toast.info('No updates');
         };
    };

    const onChangeCountry = (event, newValue) => {
        setCountry(newValue);
        getRegions(newValue.id);
        setCities([]);
        setRegion(null);
        setCity(null);
    };
    const onChangeRegion = (event, newValue) => {
        setRegion(newValue);
        getCities(newValue.id);
        setCity(null);
    };

    const getOptionLabel = (option) => {
        if (option === null) {
            return option;
        };
        return option.name
    };
    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        };
        return option.id === value.id;
    };

    return (
        <Container>
            <MainMenu />
            <Typography variant='h3' align='center' gutterBottom>
                My profile
            </Typography>
            <Paper sx={{padding: 2}}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12}>
                            <TextField label='Company name' value={company} required fullWidth onChange={(e) => {setCompany(e.target.value)}} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField label='Street' value={street} fullWidth onChange={(e) => {setStreet(e.target.value)}} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField label='Postal code' value={zip} onChange={(e) => {setZip(e.target.value)}} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField label='Phone' helperText='Int. format e.g. +23012345678' value={phone} required fullWidth onChange={(e) => {setPhone(e.target.value)}} />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete 
                                options={countries} 
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={country}
                                onChange={onChangeCountry}
                                renderInput={(params) => <TextField {...params} label='Country' />} 
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete 
                                options={regions} 
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={region}
                                onChange={onChangeRegion}
                                renderInput={(params) => <TextField {...params} label='Region' />} 
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Autocomplete 
                                options={cities} 
                                getOptionLabel={getOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                value={city}
                                onChange={(e, nV) => {setCity(nV)}}
                                renderInput={(params) => <TextField {...params} label='City' />} 
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' endIcon={<UpdateIcon />}>
                                {isLoading ? ( <Loading /> ) : 'Update'}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}

export default Profile;