import moment from 'moment';
import React from 'react';
import { CardContent, Typography, Table, TableBody, TableRow, TableCell, Divider } from '@mui/material';

import { displayClientName } from '../../utils/Utils';

function BookingSummary ({booking}) {
    return (
        <CardContent>
            <Typography variant='h6' gutterBottom>
                Booking {booking.reference}
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
                {displayClientName(booking.client)}
            </Typography>
            <Typography variant='subtitle1' gutterBottom>
                {`${booking.vehicle.registration} ${booking.vehicle.model.vehicle_make} ${booking.vehicle.model.vehicle_model}`}
            </Typography>
            <Typography variant='body1'>
                Pick-up: {moment(booking.pickup_datetime).format('D-MMM-YYYY')} at {booking.pickup_location ? booking.pickup_location.location : booking.delivery_address}
            </Typography>
            <Typography variant='body1' gutterBottom>
                Drop-off: {moment(booking.dropoff_datetime).format('D-MMM-YYYY')} at {booking.dropoff_location ? booking.dropoff_location.location : booking.collection_address}
            </Typography>
            <Divider sx={{marginBottom: '20px'}} />
            <Typography variant='subtitle2' gutterBottom>
                Items
            </Typography>
            <Table sx={{marginBottom: '20px'}}>
                <TableBody>
                    {Object.entries(booking.booking_summary).map(([label, value]) => (
                        label === 'rental' ? (
                            <TableRow key={label}>
                                <TableCell>{value[0]} days rental</TableCell>
                                <TableCell>{value[1]}</TableCell>
                            </TableRow>
                        ) : (
                            <TableRow key={label}>
                                <TableCell>{value[0]}</TableCell>
                                <TableCell>{value[1]}</TableCell>
                            </TableRow>
                        )
                    ))}
                </TableBody>
            </Table>
        </CardContent>
    )
}

export default BookingSummary;