import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';



export default function AuthComponent({Component}) {
    const { isAuthenticated } = useSelector((state) => state.auth);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect( () => {
        const checkAuth = () => {
            if (!isAuthenticated) {
                const redirectAfterLogin = location.pathname;
                navigate(`/login?next=${redirectAfterLogin}`);
            }
        };
        checkAuth();
    }, [isAuthenticated, location, navigate]);
    
    return (
        <div>
            {isAuthenticated ? <Component /> : null}
        </div>
    )
}