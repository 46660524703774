import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toastOnError } from '../../utils/Utils';
import { toast } from 'react-toastify';

const initialState = {
    discounts: {},
    booking: {},
    isLoading: false
}


export const getBooking = createAsyncThunk(
    'discounts/booking',
    async (bookingRef, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`ops/bookings?ref=${bookingRef}`);
            dispatch(setBooking(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const createDiscount = createAsyncThunk(
    'discount/create',
    async (discountData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('ops/discounts/', discountData);
            dispatch(addDiscount(response.data));
            toast.success('Discount added');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateDiscount = createAsyncThunk(
    'discount/update',
    async (discount, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`ops/discounts/${discount.id}/`, discount);
            dispatch(editDiscount(response.data));
            toast.success('Discount updated');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const deleteDiscount = createAsyncThunk(
    'discount/delete',
    async (discountId, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.delete(`ops/discounts/${discountId}/`);
            dispatch(removeDiscount(discountId));
            toast.success('Discount deleted');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getDiscounts = createAsyncThunk(
    'discounts/get',
    async (bookingRef, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`ops/discounts?ref=${bookingRef}`);
            dispatch(setDiscounts(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const discountsSlice = createSlice({
    name: 'discounts',
    initialState,
    reducers: {
        setBooking (state, action) {
            state.booking = action.payload[0];
        },
        setDiscounts (state, action) {
            state.discounts = action.payload;
        },
        editDiscount (state, action) {
            const updatedDiscount = action.payload;
            const discounts = state.discounts;
            state.discounts = discounts.map((discount) => discount.id === updatedDiscount.id ? {...discount, ...updatedDiscount} : discount)
        },
        removeDiscount (state, action) {
            const discounts = state.discounts;
            state.discounts = discounts.filter((discount) => discount.id !== action.payload)
        },
        addDiscount (state, action) {
            const discounts = state.discounts;
            state.discounts = [...discounts, action.payload]
        }
    },
    extraReducers (builder) {
        builder.addCase(getDiscounts.pending, (state) => {
            state.isLoading = true;
        }).addCase(getDiscounts.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getDiscounts.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getBooking.pending, (state) => {
            state.isLoading = true;
        }).addCase(getBooking.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getBooking.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateDiscount.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateDiscount.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateDiscount.rejected, (state) => {
            state.isLoading = false;
        }).addCase(createDiscount.pending, (state) => {
            state.isLoading = true;
        }).addCase(createDiscount.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(createDiscount.rejected, (state) => {
            state.isLoading = false;
        }).addCase(deleteDiscount.pending, (state) => {
            state.isLoading = true;
        }).addCase(deleteDiscount.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(deleteDiscount.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setDiscounts, setBooking, editDiscount, removeDiscount, addDiscount } = discountsSlice.actions;
export default discountsSlice.reducer;
