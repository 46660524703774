import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';



const initialState = {
    ops: {},
    filter: 0,
    isLoading: false
}


export const getOperations = createAsyncThunk(
    'ops/bookings',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/ops/operations/');
            dispatch(setOperations(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateOperation = createAsyncThunk(
    'ops/update',
    async (opData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`/ops/operations/${opData.id}/`, opData);
            dispatch(updateOps(response.data));
            toast.success('Operation updated');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const operationsSlice = createSlice({
    name: 'operations',
    initialState,
    reducers: {
        setOperations (state, action) {
            state.ops = action.payload;
        },
        unsetOperations (state) {
            state.ops = {};
        },
        updateOps (state, action) {
            const updatedOp = action.payload;
            const ops = state.ops;
            state.ops = ops.map((op) => op.id === updatedOp.id ? {...op, ...updatedOp} : op)
        },
        setFilter (state, action) {
            state.filter = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOperations.pending, (state) => {
            state.isLoading = true;
        }).addCase(getOperations.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getOperations.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateOperation.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateOperation.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateOperation.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setOperations, unsetOperations, updateOps, setFilter } = operationsSlice.actions;
export default operationsSlice.reducer;