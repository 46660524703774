import { configureStore } from "@reduxjs/toolkit";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from "history";

import signupReducer from "./components/signup/SignupSlice";
import loginReducer from "./components/login/LoginSlice";
import accountReducer from "./components/account/AccountSlice";
import fleetReducer from "./components/fleet/FleetSlice";
import teamReducer from "./components/team/TeamSlice";
import operationsReducer from "./components/operations/OperationsSlice";
import bookingsReducer from "./components/bookings/BookingsSlice";
import discountsReducer from "./components/discounts/DiscountsSlice";
import ratesReducer from "./components/rates/RatesSlice";
import plannerReducer from "./components/planner/PlannerSlice";

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({ history: createBrowserHistory() });

export const store = configureStore({
    reducer: {
        router: routerReducer,
        createUser: signupReducer,
        auth: loginReducer,
        account: accountReducer,
        fleet: fleetReducer,
        team: teamReducer,
        ops: operationsReducer,
        bookings: bookingsReducer,
        discounts: discountsReducer,
        rates: ratesReducer,
        planner: plannerReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(routerMiddleware),
});

export const history = createReduxHistory(store);
