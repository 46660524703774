import React, {useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Card, Box, CardActions, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

import LoadingBox from '../../utils/LoadingBox';
import BookingSummary from './BookingSummary';
import { getBookings, updateBooking } from './BookingsSlice';

function BookingDetail () {
    const { isLoading, bookings } = useSelector((state) => state.bookings);
    const { bookingId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(bookings).length === 0) {
            dispatch(getBookings());
        }
    }, [bookings, dispatch]);

    const booking = Object.keys(bookings).length > 0 ? bookings.find((booking) => parseInt(booking.id) === parseInt(bookingId)) : {};
    const [status, setStatus] = useState(booking.status || '');

    const handleUpdate = () => {
        const bookingData = {
            id: bookingId,
            action: 'bookings',
            status: status
        }
        dispatch(updateBooking(bookingData));
        navigate('/manage/bookings');
    }

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Booking details
            </Typography>
            <LoadingBox isLoading={isLoading} elements={bookings} noneText={'No bookings'} />
            {Object.keys(booking).length > 0 ? (
                <Card sx={{padding: 2}}>
                    <BookingSummary booking={booking} />
                    <CardActions>
                        <Box sx={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            <FormControl sx={{marginRight: '20px'}} size='small'>
                                <InputLabel id='id-booking-status'>Status</InputLabel>
                                <Select
                                    labelId='id-booking-status'
                                    value={status}
                                    label='Status'
                                    onChange={(e) => setStatus(e.target.value.toString())}
                                >
                                    <MenuItem value={1}>Active</MenuItem>
                                    <MenuItem value={2}>Dispatched</MenuItem>
                                    <MenuItem value={3}>Completed</MenuItem>
                                    <MenuItem value={4}>Cancelled</MenuItem>
                                </Select>
                            </FormControl>
                            <Button onClick={handleUpdate} variant='contained' endIcon={<UpdateIcon />}>Update</Button>
                        </Box>
                    </CardActions>
                </Card>
            ) : null}
        </Container>
    )
}

export default BookingDetail;