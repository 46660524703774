import axios from "axios";
import { toast } from "react-toastify";

export const setAxiosAuthToken = token => {
    if (typeof token !== "undefined" && token) {
        axios.defaults.headers.common["Authorization"] = "Token " + token;
    } else {
        delete axios.defaults.headers.common["Authorization"];
    }
};

export const toastOnError = error => {
    if (error.response) {
        toast.error(JSON.stringify(error.response.data));
    } else if (error.message) {
        toast.error(JSON.stringify(error.message));
    } else {
        toast.error(JSON.stringify(error));
    }
};

export const isEmpty = value =>
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "object" && Object.values(value).every(value => value === '' || value === null)) ||
    (typeof value === "string" && value.trim().length === 0)
    
export const displayClientName = user => {
    const firstName = user.first_name
    const lastName = user.last_name
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase() + ' ' + lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase()
};

export const handleIntegerField = (value, setterFunction) => {
    const parsedValue = parseInt(value, 10);
    if (!isNaN(parsedValue)) {
        setterFunction(parsedValue);
    } else {
        toast.error('Not a number');
    }
}

export const handleDecimalField = (value, setterFunction) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
        setterFunction(parsedValue.toFixed(2));
    } else {
        toast.error('Not a number');
    }
}

export const handlePercentField = (value, setterFunction) => {
    const parsedValue = parseFloat(value);
    if (!isNaN(parsedValue) && parsedValue >= -1 && parsedValue <= 1) {
        setterFunction(parsedValue.toFixed(2));
    } else {
        toast.error('Percent must be -1 to 1');
    }
}