import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, Grid, Box, TextField, Button } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';

import { getOperations, updateOperation } from './OperationsSlice';
import { getTeam } from '../team/TeamSlice';
import { displayClientName } from '../../utils/Utils';
import Loading from '../../utils/Loading';
import MultiSelect from '../../utils/MultiSelect';

function UpdateOps () {
    const { ops, isLoading: isLoadingOps } = useSelector((state) =>  state.ops);
    const { team, isLoading: isLoadingTeam } = useSelector((state) => state.team);
    const { opId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(ops).length === 0) {
            dispatch(getOperations());
        }
        if (Object.keys(team).length === 0) {
            dispatch(getTeam());
        }
    }, [ops, team, dispatch]);

    const op = getOperation(ops, opId);
    const [dispatchDriver, setDispatchDriver] = useState(op.dispatch_driver || []);
    const [returnDriver, setReturnDriver] = useState(op.return_driver || []);
    const [remarks, setRemarks] = useState(op.remarks || '');
    const drivers = getDrivers(team);

    const handleSubmit = (event) => {
        event.preventDefault();
        const opData = {
            id: op.id,
            dispatch_driver: dispatchDriver,
            return_driver: returnDriver,
            remarks: remarks
        }
        dispatch(updateOperation(opData));
        navigate('/manage/ops')
    };

    const handleDispatchDriver = (event) => {
        const { target: {value}, } = event;
        setDispatchDriver(typeof value === 'string' ? value.split(',') : value)
    };
    const handleReturnDriver = (event) => {
        const { target: {value}, } = event;
        setReturnDriver(typeof value === 'string' ? value.split(',') : value)
    };

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Update operation
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {isLoadingOps || isLoadingTeam ? <Loading /> : null}
            </Box>
            <Paper sx={{padding: 2, marginBottom: '20px'}}>
                <Typography variant='subtitle2' gutterBottom>
                    Booking details for {Object.keys(op).length > 0 ? op.booking.reference : ''}
                </Typography>
                <Typography variant='body1' gutterBottom>
                    {Object.keys(op).length > 0 ? primaryListItemText(op) : ''}
                </Typography>
                <Typography variant='body2' gutterBottom>
                    {Object.keys(op).length > 0 ? secondaryListItemText(op) : ''}
                </Typography>
                {Object.keys(op).length > 0 ? <PaymentStatus op={op} /> : ''}
            </Paper>
            <Paper sx={{padding: 2}}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12} md={6}>
                            <MultiSelect
                                inputLabel={'Dispatch drivers'}
                                values={drivers}
                                valuesState={dispatchDriver}
                                labelFunction={displayClientName}
                                onChange={handleDispatchDriver}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MultiSelect
                                inputLabel={'Return drivers'}
                                values={drivers}
                                valuesState={returnDriver}
                                labelFunction={displayClientName}
                                onChange={handleReturnDriver}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField label='Remarks' value={remarks} onChange={(e) => setRemarks(e.target.value)} fullWidth multiline maxRows={10} />
                        </Grid>
                        <Grid item xs={12}>
                            <Button type='submit' variant='contained' endIcon={<UpdateIcon />} >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </Paper>
        </Container>
    )
}

export default UpdateOps;

export const getOperation = (ops, opId) => {
    if (Object.keys(ops).length === 0) {
        return [];
    }
    return ops.filter((op) => {return (op.id.toString() === opId)})[0]
}

export const getDrivers = (team) => {
    if (Object.keys(team).length === 0) {
        return [];
    }
    return team.filter((member) => {return (member.group.name === 'Drivers')}).map(member => member.user)
}

export const primaryListItemText = (op) => {
    var location = ''
    var opTime = ''
    if (op.operation_due === 'Dispatch') {
        location = op.booking.pickup_location ? op.booking.pickup_location.location : op.booking.delivery_address
        opTime = moment(op.booking.pickup_datetime).format('D-MM-YY HH:mm');
    }
    if (op.operation_due === 'Return') {
        location = op.booking.dropoff_location ? op.booking.dropoff_location.location : op.booking.collection_address
        opTime = moment(op.booking.dropoff_datetime).format('D-MM-YY HH:mm');
    }
    const clientName = displayClientName(op.booking.client)
    return (`${op.operation_due} > ${opTime} | ${location} | ${op.booking.vehicle.registration} ${op.booking.vehicle.model.vehicle_make} ${op.booking.vehicle.model.vehicle_model} | ${clientName}`)
};

export const secondaryListItemText = (op) => {
    const extras = op.booking.extras.map((extra) => {
        return `| ${extra.code}`
    });
    return `${op.booking.insurance_waiver.code} ${[...extras].join(' ')}`
}

export const PaymentStatus = ({op}) => {
    const percentPaid = parseInt(op.percent_paid.slice(0, -1))
    if (percentPaid <= 0) {
        return (
            <Box sx={{paddingTop: 1, paddingBottom: 1, paddingRight: 1, display: 'flex', alignItems: 'center'}}>
                <WarningAmberIcon color='error' />
                <Typography sx={{ paddingLeft: 1}}>{op.percent_paid} of booking value paid</Typography>
            </Box>
        )
    }
    if (percentPaid < 100) {
        return (
            <Box sx={{paddingTop: 1, paddingBottom: 1, paddingRight: 1, display: 'flex', alignItems: 'center'}}>
                <PendingIcon color='warning' />
                <Typography sx={{ paddingLeft: 1}}>{op.percent_paid} of booking value paid</Typography>
            </Box>
        )
    }
    if (percentPaid >= 100) {
        return (
            <Box sx={{paddingTop: 1, paddingBottom: 1, paddingRight: 1, display: 'flex', alignItems: 'center'}}>
                <CheckCircleOutlineIcon color='success' />
                <Typography sx={{ paddingLeft: 1}}>{op.percent_paid} of booking value paid</Typography>
            </Box>
        )
    }
}