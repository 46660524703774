import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';


const initialState = {
    fleet: {},
    models: {},
    locations: {},
    isLoading: false
}


export const getFleet = createAsyncThunk(
    'fleet/cars',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/fleet/vehicles/');
            dispatch(setFleet(response.data));
            dispatch(getModels(user));
            dispatch(getLocations(user));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const updateVehicle = createAsyncThunk(
    'fleet/update',
    async (vehicle, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`/fleet/vehicles/${vehicle.id}/`, vehicle);
            dispatch(updateFleet(response.data));
            toast.success('Car updated');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const createVehicle = createAsyncThunk(
    'fleet/create',
    async (vehicle, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/fleet/vehicles/', vehicle);
            dispatch(extendFleet(response.data));
            toast.success('Car created');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const deleteVehicle = createAsyncThunk(
    'fleet/delete',
    async (vehicleId, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.delete(`/fleet/vehicles/${vehicleId}/`);
            dispatch(reduceFleet(vehicleId));
            toast.success('Car deleted');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getModels = createAsyncThunk(
    'fleet/models',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/etc/models/');
            dispatch(setModels(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

export const getLocations = createAsyncThunk(
    'fleet/locations',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/etc/locations/');
            dispatch(setLocations(response.data));
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const fleetSlice = createSlice({
    name: 'fleet',
    initialState,
    reducers: {
        setFleet (state, action) {
            state.fleet = action.payload;
        },
        updateFleet (state, action) {
            const updatedVehicle = action.payload;
            const fleet = state.fleet;
            state.fleet = fleet.map((vehicle) => vehicle.id === updatedVehicle.id ? {...vehicle, ...updatedVehicle} : vehicle)
        },
        extendFleet (state, action) {
            const fleet = state.fleet;
            state.fleet = [...fleet, action.payload]
        },
        reduceFleet (state, action) {
            const fleet = state.fleet;
            state.fleet = fleet.filter(vehicle => vehicle.id !== action.payload);
        },
        setModels (state, action) {
            state.models = action.payload;
        },
        setLocations (state, action) {
            state.locations = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getFleet.pending, (state) => {
            state.isLoading = true;
        }).addCase(getFleet.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getFleet.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getModels.pending, (state) => {
            state.isLoading = true;
        }).addCase(getModels.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getModels.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getLocations.pending, (state) => {
            state.isLoading = true;
        }).addCase(getLocations.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getLocations.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateVehicle.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateVehicle.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateVehicle.rejected, (state) => {
            state.isLoading = false;
        }).addCase(createVehicle.pending, (state) => {
            state.isLoading = true;
        }).addCase(createVehicle.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(createVehicle.rejected, (state) => {
            state.isLoading = false;
        }).addCase(deleteVehicle.pending, (state) => {
            state.isLoading = true;
        }).addCase(deleteVehicle.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(deleteVehicle.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setFleet, updateFleet, extendFleet, reduceFleet, setModels, setLocations } = fleetSlice.actions;
export default fleetSlice.reducer;
