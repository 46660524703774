import React from "react";
import { useNavigate } from "react-router-dom";
import { Container, Typography, Grid } from '@mui/material';
import { Card, CardActionArea, CardMedia, CardContent } from '@mui/material';
import CarRentalOutlinedIcon from '@mui/icons-material/CarRentalOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Groups2Icon from '@mui/icons-material/Groups2';
import CastIcon from '@mui/icons-material/Cast';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import MainMenu from '../menu/MainMenu';


function Dashboard () {
    const navigate = useNavigate();

    return (
        <Container>
            <MainMenu />
            <Typography variant="h3" align="center" gutterBottom>
                Dashboard
            </Typography>
            <Grid container spacing={2} alignSelf='center'>
                <Grid item xs={6} md={4}>
                    <Card sx={{maxWidth: 345}} elevation={3}>
                        <CardActionArea onClick={() => navigate('/manage/ops')}>
                            <CardMedia sx={{display: 'flex', justifyContent: 'space-between', padding: '8px'}}>
                                <CastIcon color="primary" fontSize="large" />
                                <ArrowForwardIcon color="primary" fontSize="large" />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    My operations
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    Manage car dispatch and returns
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{maxWidth: 345}} elevation={3}>
                        <CardActionArea onClick={() => navigate('/manage/planner')}>
                            <CardMedia sx={{display: 'flex', justifyContent: 'space-between', padding: '8px'}}>
                                <CalendarMonthIcon color="primary" fontSize="large" />
                                <ArrowForwardIcon color="primary" fontSize="large" />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    My planner
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    View your current and upcoming rentals
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{maxWidth: 345}} elevation={3}>
                        <CardActionArea onClick={() => navigate('/manage/bookings')}>
                            <CardMedia sx={{display: 'flex', justifyContent: 'space-between', padding: '8px'}}>
                                <CollectionsBookmarkIcon color="primary" fontSize="large" />
                                <ArrowForwardIcon color="primary" fontSize="large" />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    My bookings
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    View your rental bookings
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{maxWidth: 345}} elevation={3}>
                        <CardActionArea onClick={() => navigate('/manage/fleet')}>
                            <CardMedia sx={{display: 'flex', justifyContent: 'space-between', padding: '8px'}}>
                                <CarRentalOutlinedIcon color="primary" fontSize="large" />
                                <ArrowForwardIcon color="primary" fontSize="large" />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    My cars
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    Manage your vehicle fleet
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{maxWidth: 345}} elevation={3}>
                        <CardActionArea onClick={() => navigate('/manage/team')}>
                            <CardMedia sx={{display: 'flex', justifyContent: 'space-between', padding: '8px'}}>
                                <Groups2Icon color="primary" fontSize="large" />
                                <ArrowForwardIcon color="primary" fontSize="large" />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    My team
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    Manage your team
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Card sx={{maxWidth: 345}} elevation={3}>
                        <CardActionArea onClick={() => navigate('/manage/partner/rates')}>
                            <CardMedia sx={{display: 'flex', justifyContent: 'space-between', padding: '8px'}}>
                                <PriceChangeOutlinedIcon color="primary" fontSize="large" />
                                <ArrowForwardIcon color="primary" fontSize="large" />
                            </CardMedia>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Rental rates
                                </Typography>
                                <Typography variant="body2" color="text.secondary" gutterBottom>
                                    Manage daily rental rates
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Dashboard