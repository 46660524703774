import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Container, Grid, Typography, Card, CardContent, CardActions, Button, TextField } from '@mui/material';
import UpdateIcon from '@mui/icons-material/Update';

import MainMenu from '../menu/MainMenu';
import Loading from '../../utils/Loading';
import { resetPasswordConfirm } from './AccountSlice';


export default function PwdResetConfirm () {
    const dispatch = useDispatch();
    const { isLoading, pwdReset } = useSelector((state) => state.account);
    const { uid, token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (password === confirmPassword) {
            const pwdData = {
                uid: uid,
                token: token,
                new_password: password,
                re_new_password: confirmPassword
            }
            dispatch(resetPasswordConfirm(pwdData));
        } else {
            toast.error('Password mismatch');
        }
    };

    return (
        <Container>
            <MainMenu />
            <Grid container spacing={2} justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Card sx={{maxWidth: 375}} style={{margin: 'auto'}}>
                            <CardContent>
                                <Typography gutterBottom variant='h5' component='div'>
                                    Confirm password reset
                                </Typography>
                                <Typography variant='body2' color='text.secondary'>
                                    Create your new account password.
                                </Typography>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField label='New password' required type='password' onChange={(e) => setPassword(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                    <TextField label='Confirm password' required type='password' onChange={(e) => setConfirmPassword(e.target.value)} />
                                    </Grid>
                                </Grid>
                            </CardContent> 
                            <CardActions>
                                <Button type='submit' disabled={pwdReset} variant='contained' endIcon={<UpdateIcon />}>
                                    {isLoading ? <Loading /> : 'Reset'}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}