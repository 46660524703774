import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from'react-redux';
import { Autocomplete, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Paper, TextField, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

import LoadingBox from '../../utils/LoadingBox';
import { getPartnerRates, getBrackets, createPartnerRate, updatePartnerRate, deletePartnerRate } from './RatesSlice';
import { getModels } from '../fleet/FleetSlice';
import { handleDecimalField, isEmpty } from '../../utils/Utils';

function PartnerRates () {
    const { isLoading, partnerRates, brackets } = useSelector((state) => state.rates)
    const { models } = useSelector((state) => state.fleet);
    const dispatch = useDispatch();
    const [fetchRates, setFetchRates] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [myBracket, setMyBracket] = useState(null);
    const [myModel, setMyModel] = useState(null);
    const [myRate, setMyRate] = useState(0.00);
    const [myStart, setMyStart] = useState(moment());
    const [myEnd, setMyEnd] = useState(moment().add(1, 'days'));
    const [objId, setObjId] = useState(null);

    useEffect(() => {
        if (fetchRates) {
            dispatch(getPartnerRates());
            dispatch(getBrackets());
            dispatch(getModels());
            setFetchRates(false);
        }
    }, [fetchRates, dispatch])

    const handleCreateRate = () => {
        setDialogTitle('Create');
        setDialogOpen(true);
    }

    const handleUpdateRate = (row) => {
        setDialogTitle('Update');
        setObjId(row.rateObj.id);
        setMyBracket(brackets.find((bracket) => bracket.id === row.rateObj.period_bracket));
        setMyModel(models.find((model) => model.id === row.rateObj.vehicle_model));
        setMyRate(parseFloat(row.rate).toFixed(2));
        setMyStart(moment(row.start));
        setMyEnd(moment(row.end));
        setDialogOpen(true);
    }

    const handleDeleteRate = (row) => {
        setDialogTitle('Delete');
        setObjId(row.rateObj.id);
        setMyBracket(brackets.find((bracket) => bracket.id === row.rateObj.period_bracket));
        setMyModel(models.find((model) => model.id === row.rateObj.vehicle_model));
        setMyRate(parseFloat(row.rate).toFixed(2));
        setMyStart(moment(row.start));
        setMyEnd(moment(row.end));
        setDialogOpen(true);
    }

    const handleCleanup = () => {
        setDialogTitle('');
        setObjId(null);
        setMyBracket(null);
        setMyModel(null);
        setMyRate(0.00);
        setMyStart(moment());
        setMyEnd(moment().add(1, 'days'));
        setDialogOpen(false);
    }

    const columns = [
        {field: 'model', headerName: 'Vehicle Model'},
        {field: 'bracket', headerName: 'Rental duration'},
        {field: 'rate', headerName: 'Daily rate'},
        {field: 'start', headerName: 'From'},
        {field: 'end', headerName: 'To'},
        {field: 'edit', headerName: 'Edit', type: 'actions', getActions: (params) => [
            <IconButton aria-label='Edit' onClick={() => handleUpdateRate(params.row)}>
                <EditIcon />
            </IconButton>
        ]},
        {field: 'delete', headerName: 'Delete', type: 'actions', getActions: (params) => [
            <IconButton aria-label='Delete' onClick={() => handleDeleteRate(params.row)}>
                <DeleteIcon />
            </IconButton>
        ]}
    ]

    const rows = partnerRates.length > 0 ? partnerRates.map((rate) => (
        {id: rate.id, rateObj: rate, model: getVehicleModelValue(models, rate.vehicle_model), bracket: getBracketValue(brackets, rate.period_bracket), rate: rate.daily_rate, start: rate.start_date, end: rate.end_date}
    )) : []

    const getModelOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return `${option.vehicle_make} ${option.vehicle_model}`
    };

    const getBracketOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return `${option.from_days} to ${option.to_days} days`
    };

    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        }
        return value.id === option.id;
    }

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                My rental rates
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '5px'}}>
                <Button onClick={handleCreateRate} type='button' endIcon={<AddIcon />}>Add rate</Button>  
            </Box>
            <Paper sx={{ padding: 2 }}>
                <LoadingBox isLoading={isLoading} elements={partnerRates} noneText={'No own rates'} />
                <DataGrid
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row.id}
                    autoHeight
                    initialState={{
                        pagination: {
                            paginationModel: {page: 0, pageSize: 10},
                        },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                />
            </Paper>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    if (isEmpty(myModel) || isEmpty(myBracket) || myEnd.isBefore(myStart) || myRate <= 0) {
                        toast.error('Invalid rate details');
                        return;
                    }
                    const rateData = {
                        id: objId,
                        model: myModel.id,
                        bracket: myBracket.id,
                        rate: myRate,
                        period: `${myStart.valueOf()}-${myEnd.valueOf()}`
                    }
                    if (dialogTitle === 'Create') {
                        dispatch(createPartnerRate(rateData));
                    }
                    if (dialogTitle === 'Update') {
                        dispatch(updatePartnerRate(rateData));
                    }
                    if (dialogTitle === 'Delete') {
                        dispatch(deletePartnerRate(objId));
                    }
                    handleCleanup();
                }
            }}>
                <DialogTitle>{`${dialogTitle} daily rate`}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Rate details
                    </DialogContentText>
                    <Grid container spacing={2} align='left' alignItems='center'>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                value={myModel}
                                onChange={(event, value) => setMyModel(value)}
                                options={Object.keys(models).length > 0 ? models : []}
                                getOptionLabel={getModelOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                renderInput={(params) => <TextField {...params} label='Model'/>}
                                sx={{marginRight: '10px', marginBottom: '10px'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                value={myBracket}
                                onChange={(event, value) => setMyBracket(value)}
                                options={Object.keys(brackets).length > 0 ? brackets : []}
                                getOptionLabel={getBracketOptionLabel}
                                isOptionEqualToValue={isOptionEqualToValue}
                                renderInput={(params) => <TextField {...params} label='Duration'/>}
                                sx={{marginRight: '10px', marginBottom: '10px'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label='From date'
                                value={myStart}
                                onChange={(newValue) => setMyStart(moment(newValue))}
                                minDate={moment()}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <DatePicker
                                label='To date'
                                value={myEnd}
                                onChange={(newValue) => setMyEnd(moment(newValue))}
                                minDate={moment().add(1, 'days')}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField label='Daily rate' type='number' value={myRate} onChange={(event) => handleDecimalField(event.target.value, setMyRate)} fullWidth/>
                        </Grid>
                    </Grid>
                    <DialogContentText sx={{marginTop: '10px'}}>
                        Rates below the default rate cannot exceed the maximum permissible validity.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color='secondary'>Cancel</Button>
                    <Button type='submit' variant='contained'>{dialogTitle}</Button>
                </DialogActions>
            </Dialog>
        </Container>
    )
}

export default PartnerRates;

function getVehicleModelValue(models, id) {
    const model = !isEmpty(models) ? models.find(model => model.id === id) : null;
    if (!model) {
        return ''
    }
    return `${model.vehicle_make} ${model.vehicle_model}`
}

function getBracketValue(brackets, id) {
    const bracket = !isEmpty(brackets) ? brackets.find(bracket => bracket.id === id) : null;
    if (!bracket) {
        return ''
    }
    return `${bracket.from_days} to ${bracket.to_days} days`
}