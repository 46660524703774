import React from 'react';
import { TextField, Button, Grid, Typography } from '@mui/material';

import Loading from '../../utils/Loading';


export default function AccountForm(props) {
    
    return (
        <form onSubmit={props.onSubmit}>
            <Grid container spacing={2}  align="center">
                <Grid item xs={12} md={4}>
                    <TextField label="First name" value={props.user.firstName} required onChange={props.onChangeFirstName} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="Last name" value={props.user.lastName} required onChange={props.onChangeLastName} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="Username" value={props.user.username} required onChange={props.onChangeUsername} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="Email" value={props.user.email} type="email" required onChange={props.onChangeEmail} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="Password" required={!props.isAuthenticated} type="password" onChange={props.onChangePassword} />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField label="Confirm password" required={!props.isAuthenticated} type="password" onChange={props.onChangeConfirmPassword} />
                </Grid>
                {props.isAuthenticated ? (
                    <Grid item xs={12} md={4}>
                        <TextField label="Current password" required={!props.isAuthenticated} type="password" onChange={props.onChangeOldPassword} />
                    </Grid>
                ) : null}
                {props.isAuthenticated ? (
                    <Grid item xs={12} md={8}>
                        <Typography variant='body2' color='text.secondary' align='left'>
                            To change your password provide your current password, otherwise leave password fields blank.
                        </Typography>
                        </Grid>
                 ) : null}
                <Grid item xs={12}>
                    <Button type="submit" variant="contained" endIcon={props.endIcon}>
                        {props.isLoading ? <Loading /> : props.buttonLabel}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}