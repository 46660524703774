import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Paper } from '@mui/material';

import FleetForm from './FleetForm';
import { createVehicle } from './FleetSlice';


function CreateVehicle () {
    const { isLoading } = useSelector((state) => state.fleet);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [registration, setRegistartion] = useState('');
    const [model, setModel] = useState(null);
    const [location, setLocation] = useState(null);
    const vehicle = {
        registration: registration,
        model: model,
        location: location
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        dispatch(createVehicle(vehicle));
        navigate('/manage/fleet');
    }

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Add car
            </Typography>
            <Paper sx={{padding: 2}}>
                <FleetForm
                    action='create'
                    isLoading={isLoading}
                    vehicle={vehicle}
                    onRegistrationChange={setRegistartion}
                    onModelChange={setModel}
                    onLocationChange={setLocation}
                    handleSubmit={handleSubmit}
                />
            </Paper>
        </Container>
    )
}

export default CreateVehicle