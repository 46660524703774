import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { toastOnError } from '../../utils/Utils';


const initialState = {
    plan: {},
    isLoading: false
}


export const getPlan = createAsyncThunk(
    'admin/planner',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('admin/planner/');
            dispatch(setPlan(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const plannerSlice = createSlice({
    name: 'planner',
    initialState,
    reducers: {
        setPlan (state, action) {
            state.plan = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPlan.pending, (state) => {
            state.isLoading = true;
        }).addCase(getPlan.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getPlan.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setPlan } = plannerSlice.actions;
export default plannerSlice.reducer;