import axios from 'axios';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';

export const getCountries = async (setCountryList) => {
    try {
        const response = await axios.get('/etc/countries/');
        setCountryList(response.data);
    } catch (error) {
        toastOnError(error);
    }
}

export const getInsurances = async (setInsuranceList, setInsurance) => {
    try {
        const response = await axios.get('/etc/insurances/');
        setInsuranceList(response.data);
        setInsurance(response.data[0]);
    } catch (error) {
        toastOnError(error);
    }
}

export const getQuotation = async (vehicle, period, insurance, setQuote) => {
    try {
        const response = await axios.get(`/admin/quote?vehicle=${vehicle}&period=${period}&inw=${insurance}`);
        setQuote(response.data);
    } catch (error) {
        toastOnError(error);
    }
}

export const getAvailableVehicles = async (action, param, setAvailableVehicles) => {
    if (action === 'booking') {
        const response = await axios.get(`/admin/availability/?ref=${param}`);
        setAvailableVehicles(response.data);
    }
    if (action === 'period') {
        const response = await axios.get(`/admin/availability/?period=${param}`);
        setAvailableVehicles(response.data);
    }
}

export const getPaymentTerms = async (setPaymentTerms) => {
    const response = await axios.get('/etc/payterms/');
    setPaymentTerms(response.data);
}

export const createBooking = async (bookingData, setPlanRefresh) => {
    try {
        const response = await toast.promise(
            axios.post('/ops/bookings/', bookingData),
            {
                pending: 'Processing...',
                success: 'Success',
                error: 'Failed'
            }
        ) 
        setPlanRefresh(true);
        toast.success(`Booking ${response.data.booking.reference} created. Payment request ${response.data.payment_request}`)
    } catch (error) {
        toastOnError(error);
    }
}

export const blockVehicle = async (blockData, setVehicleToBlock, setPlanRefresh) => {
    try {
        const response = await axios.post('/ops/bookings/', blockData);
        setVehicleToBlock(response.data.vehicle);
        setPlanRefresh(true);
        toast.success('Car blocked');
    } catch (error) {
        toastOnError(error)
    }
}

export const unblockVehicle = async (idToUnblock, setIdToUnblock, setPlanRefresh) => {
    try {
        const response = await axios.delete(`/ops/bookings/${idToUnblock}/`);
        setIdToUnblock(response.data);
        setPlanRefresh(true);
        toast.success('Car unblocked');
    } catch (error) {
        toastOnError(error);
    }
}

export const createPayment = async (paymentData, setPlanRefresh) => {
    try {
        const response = await axios.post('/admin/payment/', paymentData);
        setPlanRefresh(true);
        toast.success(`Payment of ${response.data.currency}${parseFloat(response.data.amount).toFixed(2)} recorded`);
    } catch (error) {
        toastOnError(error);
    }
}