import moment from 'moment';
import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Paper, IconButton, Box, FormControl, InputLabel, Select, MenuItem, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SourceIcon from '@mui/icons-material/Source';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import LoadingBox from '../../utils/LoadingBox';
import { getOperations, setFilter } from './OperationsSlice';
import { displayClientName } from '../../utils/Utils';

function Operations () {
    const { isLoading, ops, filter } = useSelector((state) => state.ops);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [dataFetched, setDataFetched] = useState(false);

    useEffect(() => {
        if (Object.keys(ops).length === 0 && !dataFetched) {
            dispatch(getOperations());
            setDataFetched(true);
        }
    }, [ops, dataFetched, dispatch])

    const filteredOps = filterOps(filter, ops);

    const columns = [
        {field: 'status', headerName: 'Status', renderCell: (params) => {
            const { value, row } = params;
            return (
                <div>
                    {value ? (
                        <Tooltip title='Action'>
                            <IconButton aria-label='Action' onClick={() => navigate(`/manage/ops/${row.id}/update`) }>
                                <WarningAmberIcon color='warning' />
                            </IconButton>
                        </Tooltip>
                    ) : (
                        <Tooltip title='Actioned'>
                            <IconButton aria-label='Actioned' onClick={() => navigate(`/manage/ops/${row.id}/update`) }>
                                <CheckCircleOutlineIcon color='success' />
                            </IconButton>
                        </Tooltip>
                    )}
                </div>
            )
        }},
        {field: 'op', headerName: 'Action'},
        {field: 'datetime', headerName: 'Date Time', flex:1},
        {field: 'location', headerName: 'Location', flex: 1},
        {field: 'vehicle', headerName: 'Vehicle', flex: 1},
        {field: 'client', headerName: 'Client', flex: 1},
        {field: 'insurance', headerName: 'Insurance'},
        {field: 'extras', headerName: 'Extras', flex:1},
        {field: 'paid', headerName: 'Paid'},
        {field: 'booking', headerName: 'Booking', renderCell: (params) => {
            return (
                <Tooltip title='Edit booking'>
                    <IconButton aria-label='Edit booking' onClick={() => navigate(`/manage/bookings/${'operations'}/${params.value}/update`)}>
                        <SourceIcon />
                    </IconButton>
                </Tooltip>
            )
        }}
    ]

    const rows = filteredOps.map((op) => ({
        id: op.id,
        status: op.warning,
        op: op.operation_due,
        datetime: op.operation_due === 'Dispatch' ? moment(op.booking.pickup_datetime).format('D-MM-YY HH:mm') : moment(op.booking.dropoff_datetime).format('D-MM-YY HH:mm'),
        location: op.operation_due === 'Dispatch' 
            ? op.booking.pickup_location ? op.booking.pickup_location.location : op.booking.delivery_address
            : op.operation_due === 'Return' ? op.booking.dropoff_location ? op.booking.dropoff_location.location : op.booking.collection_address
            : '',
        vehicle: `${op.booking.vehicle.registration} ${op.booking.vehicle.model.vehicle_make} ${op.booking.vehicle.model.vehicle_model}`,
        client: displayClientName(op.booking.client),
        insurance: op.booking.insurance_waiver.code,
        extras: [...op.booking.extras.map((extra) => extra.code)].join(' | '),
        paid: op.percent_paid,
        booking: op.booking.reference
    }))

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Operations
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px'}}>
                <FormControl>
                    <InputLabel id='id-ops-filter'>Filter</InputLabel>
                    <Select
                        labelId='id-ops-filter'
                        id='ops-filter'
                        value={filter}
                        label='Today'
                        onChange={(e) => dispatch(setFilter(parseInt(e.target.value)))}
                    >
                        <MenuItem value={0}>Today</MenuItem>
                        <MenuItem value={1}>Tomorrow</MenuItem>
                        <MenuItem value={7}>Week</MenuItem>
                        <MenuItem value={30}>Month</MenuItem>
                        <MenuItem value={365}>All</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Paper sx={{padding: 2}}>
                {isLoading || filteredOps.length === 0 ? (
                    <LoadingBox isLoading={isLoading} elements={ops} noneText={'No current ops'} />
                ) : (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.id || Math.random().toString(36).substring(2, 15)}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 10},
                            },
                        }}
                        pageSizeOptions={[10, 25, 50, 100]}
                    />
                )}
            </Paper>
        </Container>
    )
}

export default Operations;


export const filterOps = (filter, ops) => {
    if (!ops.length) {
        return [];
    }
    if (filter === 365) {
        return ops;
    };
    const today = new Date();
    const cutoffDate = new Date(today);
    cutoffDate.setDate(today.getDate() + filter);
    cutoffDate.setHours(0, 0, 0, 0);
    return ops.filter((op) => {
        const pickup = new Date(op.booking.pickup_datetime);
        const dropoff = new Date(op.booking.dropoff_datetime);
        return (
            moment(pickup).isBefore(cutoffDate) || moment(dropoff).isBefore(cutoffDate)
        )
    }).sort((a,b) => {
        const dateA = new Date(a.booking.pickup_datetime);
        const dateB = new Date(b.booking.pickup_datetime);
        return dateA.getTime() - dateB.getTime();
    });
}
