import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';
import { unsetOperations } from '../operations/OperationsSlice';



const initialState = {
    bookings: {},
    opsBooking: {},
    insurances: {},
    extras: {},
    countries: {},
    gallery: {},
    isLoading: false
}


export const getBookings = createAsyncThunk(
    'bookings/get',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/ops/bookings/');
            dispatch(setBookings(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getOpsBooking = createAsyncThunk(
    'opsBooking/get',
    async (bookingRef, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/ops/bookings?ref=${bookingRef}`);
            dispatch(setOpsBooking(response.data));
            const insurances = await axios.get('/etc/insurances/');
            dispatch(setInsurances(insurances.data));
            const extras = await axios.get('/etc/extras/');
            dispatch(setExtras(extras.data));
            const countries = await axios.get('/etc/countries/');
            dispatch(setCountries(countries.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getGallery = createAsyncThunk(
    'bookings/gallery',
    async (bookingRef, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get(`/ops/gallery?ref=${bookingRef}`);
            dispatch(setGallery(response.data));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const deleteImage = createAsyncThunk(
    'gallery/delete',
    async (imageId, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.delete(`ops/gallery/${imageId}/`);
            dispatch(removeImage(imageId));
            toast.success('Image deleted');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateBooking = createAsyncThunk(
    'bookings/update',
    async (bookingData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`/ops/bookings/${bookingData.id}/`, bookingData);
            if (bookingData.action === 'bookings') {
                dispatch(editBooking(response.data));
            }
            if (bookingData.action === 'ops') {
                dispatch(unsetOpsBooking());
                dispatch(unsetOperations());
            }
            toast.success('Booking updated');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const bookingsSlice = createSlice({
    name: 'bookings',
    initialState,
    reducers: {
        setBookings (state, action) {
            state.bookings = action.payload;
        },
        setOpsBooking (state, action) {
            state.opsBooking = action.payload[0];
        },
        setInsurances (state, action) {
            state.insurances = action.payload;
        },
        setExtras (state, action) {
            state.extras = action.payload;
        },
        setCountries (state, action) {
            state.countries = action.payload;
        },
        setGallery (state, action) {
            state.gallery = action.payload;
        },
        removeImage (state, action) {
            const gallery = state.gallery;
            state.gallery = gallery.filter((image) => image.id !== action.payload);
        },
        unsetOpsBooking (state) {
            state.opsBooking = {}
            state.insurances = {}
            state.extras = {}
            state.countries = {}
        },
        unsetGallery (state) {
            state.gallery = {};
        },
        editBooking (state, action) {
            const updatedBooking = action.payload;
            const bookings = state.bookings;
            state.bookings = bookings.map((booking) => booking.id === updatedBooking.id ? {...booking, ...updatedBooking} : booking) 
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getBookings.pending, (state) => {
            state.isLoading = true;
        }).addCase(getBookings.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getBookings.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateBooking.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateBooking.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateBooking.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getOpsBooking.pending, (state) => {
            state.isLoading = true;
        }).addCase(getOpsBooking.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getOpsBooking.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getGallery.pending, (state) => {
            state.isLoading = true;
        }).addCase(getGallery.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getGallery.rejected, (state) => {
            state.isLoading = false;
        }).addCase(deleteImage.pending, (state) => {
            state.isLoading = true;
        }).addCase(deleteImage.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(deleteImage.rejected, (state) => {
            state.isLoading = false;
        })
    }
})

export const { setBookings, setOpsBooking, setInsurances, setExtras, setCountries, setGallery, removeImage, unsetOpsBooking, unsetGallery, editBooking } = bookingsSlice.actions;
export default bookingsSlice.reducer;
