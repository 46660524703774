import React from 'react';
import { Box, Typography } from '@mui/material';

import Loading from './Loading';

function LoadingBox ({isLoading, elements, noneText}) {
    return (
        <Box sx={{display: 'flex', justifyContent:'center', alignItems: 'center'}}>
            {isLoading ? <Loading /> : null}
            {Object.keys(elements).length === 0 && !isLoading ? (
                <Typography variant='body1' align='center' gutterBottom>
                    {noneText}
                </Typography>
            ) : null}
        </Box>
    )
}

export default LoadingBox;