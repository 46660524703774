import React, {useState} from 'react';
import moment from 'moment';
import { Box, Popover, Typography } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import InfoIcon from '@mui/icons-material/Info';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import PendingIcon from '@mui/icons-material/Pending';

import { displayClientName } from '../../utils/Utils';

function CellContent({ params }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        event.stopPropagation();
        setAnchorEl(params.api.getCellElement(params.id, params.field));
    }
    const handlePopoverClose = (event) => {
        event.stopPropagation();
        setAnchorEl(null);
    }

    const percentPaid = params.value.booking && params.value.booking.percent_paid ? parseInt(params.value.booking.percent_paid.slice(0, -1), 10) :  null;
    const PaymentIcon = ({value}) => {
        if (value <= 0) {
            return <WarningAmberIcon color='error' fontSize='small' />
        }
        if (value < 100) {
            return <PendingIcon color='warning' fontSize='small' />
        }
        if (value >= 100) {
            return <CheckCircleOutlineIcon color='success' fontSize='small' />
        }
    }
    const BookingStatus = ({value}) => {
        const paid = percentPaid === 0 ? false : true;
        if (!paid) {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 1, paddingBottom: 1 }}>
                    <InfoIcon color='error' fontSize='small' />
                    <Typography sx={{ paddingLeft: 1}}>Payment pending</Typography>
                </Box>
            )
        }
        if (value === '1') {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 1, paddingBottom: 1 }}>
                    <InfoIcon color='info' fontSize='small' />
                    <Typography sx={{ paddingLeft: 1}}>Active</Typography>
                </Box>
            )
        }
        if (value === '2') {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 1, paddingBottom: 1 }}>
                    <InfoIcon color='warning' fontSize='small' />
                    <Typography sx={{ paddingLeft: 1}}>Dispatched</Typography>
                </Box>
            )
        }
        if (value === '3') {
            return (
                <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: 1, paddingBottom: 1 }}>
                    <InfoIcon color='success' fontSize='small' />
                    <Typography sx={{ paddingLeft: 1}}>Completed</Typography>
                </Box>
            )
        }
    }

    return (
        params.field !== 'registration' && params.value !== 'A' ? (
            <div>
                <Typography
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                >
                    {params.value.cellContent}
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    key={params.value.cellContent}
                    sx={{
                        pointerEvents: 'none',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    {params.value.booking.reference === 'BLOCKED' ? (
                        <Box sx={{padding: 1, display: 'flex', alignItems: 'center'}}>
                            <WarningAmberIcon color='warning' />
                            <Typography sx={{ paddingLeft: 1 }}>Vehicle blocked</Typography>
                        </Box>
                    ) : (
                        <Box sx={{ padding: 1}}>
                            <BookingStatus value={params.value.booking.status} />
                            <Typography gutterBottom>
                                {displayClientName(params.value.booking.client)}
                            </Typography>
                            <Typography gutterBottom>
                                Pickup: {moment(params.value.booking.pickup_datetime).format('D-MMM-YY HH:mm')} {!params.value.booking.pickup_location ? params.value.booking.delivery_address : params.value.booking.pickup_location.location} 
                            </Typography>
                            <Typography gutterBottom>
                                Dropoff: {moment(params.value.booking.dropoff_datetime).format('D-MMM-YY HH:mm')} {!params.value.booking.dropoff_location ? params.value.booking.collection_address : params.value.booking.dropoff_location.location} 
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}>
                                <PaymentIcon value={percentPaid} />
                                <Typography sx={{ paddingLeft: 1 }}>{params.value.booking.percent_paid} of booking value paid.</Typography>
                            </Box>
                        </Box>
                    )}
                </Popover>
            </div>
        ) : (
            <div>{params.value}</div>
        )
    );
}

export default CellContent