import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { toast } from 'react-toastify';

import { toastOnError, isEmpty } from '../../utils/Utils';


const initialState = {
    profile: {},
    pwdUpdated: false,
    pwdResetSent: false,
    pwdReset: false,
    isLoading: false
}


export const getUserProfile = createAsyncThunk(
    'account/profile',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/user/profile/');
            dispatch(setUserProfile(response.data[0]));
            if (isEmpty(response.data[0])) {
                toast.warning('Profile not complete');
            }
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateUserProfile = createAsyncThunk(
    'profile/update',
    async (profileData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`/user/profile/${profileData.id}/`, profileData);
            dispatch(setUserProfile(response.data));
            toast.success('Profile updated');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updatePassword = createAsyncThunk(
    'account/updatePwd',
    async (pwdData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/auth/users/set_password/', pwdData);
            dispatch(changePassword(response.data));
            toast.success('Password changed');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const resetPassword = createAsyncThunk(
    'account/resetPwd',
    async (emailData, {rejectWithValue}) => {
        try {
            const response = await axios.post('/auth/users/reset_password/', emailData);
            toast.success('Email sent');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const resetPasswordConfirm = createAsyncThunk(
    'account/confirmPwd',
    async (pwdData, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/auth/users/reset_password_confirm/', pwdData);
            dispatch(push('/login'));
            toast.success('Password reset');
            return response.data;
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setUserProfile (state, action) {
            state.profile = action.payload;
        },
        unsetUserProfile (state) {
            state.profile = {};
        },
        changePassword (state) {
            state.pwdUpdated = true;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(updatePassword.pending, (state) => {
            state.isLoading = true;
        }).addCase(updatePassword.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updatePassword.rejected, (state) => {
            state.isLoading = false;
        }).addCase(resetPassword.pending, (state) => {
            state.isLoading = true;
        }).addCase(resetPassword.fulfilled, (state) => {
            state.isLoading = false;
            state.pwdResetSent = true;
        }).addCase(resetPassword.rejected, (state) => {
            state.isLoading = false;
        }).addCase(resetPasswordConfirm.pending, (state) => {
            state.isLoading = true;
        }).addCase(resetPasswordConfirm.fulfilled, (state) => {
            state.isLoading = false;
            state.pwdReset = true;
        }).addCase(resetPasswordConfirm.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getUserProfile.pending, (state) => {
            state.isLoading = true;
        }).addCase(getUserProfile.fulfilled, (state) => {
            state.isLoading = false;
            state.pwdReset = true;
        }).addCase(getUserProfile.rejected, (state) => {
            state.isLoading = false;
        });
    },
});

export const { setUserProfile, unsetUserProfile, changePassword } = accountSlice.actions;
export default accountSlice.reducer;
