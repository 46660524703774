import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Typography, TextField, Button, Grid, Paper } from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { toastOnError } from "../../utils/Utils";
import Loading from "../../utils/Loading";

import { login } from "./LoginSlice";
import MainMenu from "../menu/MainMenu";


function Login () {
    const [username, setUsername ] = useState("");
    const [password, setPassword ] = useState("");
    const dispatch = useDispatch();
    const { isLoading, isAuthenticated } = useSelector((state) => state.auth);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        const userData = {
            username: username,
            password: password
        };
        try {
           dispatch(login(userData));
        } catch (error) {
            toastOnError(error);
        }
    }

    useEffect(() => {
        if(isAuthenticated && !toast.isActive()) {
            toast.info('Logged in');
        }
    }, [isAuthenticated]);

    return (
        <Container>
            <MainMenu />
            <Typography variant="h3" align="center" gutterBottom>
                Login
            </Typography>
            {!isAuthenticated ? (
                <Paper sx={{padding: 2}}>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2} align="center">
                            <Grid item xs={12}>
                                <TextField label="Username" required onChange={(e) => setUsername(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField label="Password" required type="password" onChange={(e) => setPassword(e.target.value)} />
                            </Grid>
                            <Grid item xs={12}>
                                <Button type="submit" disabled={isLoading} variant="contained" endIcon={<LoginIcon />}>{isLoading ? <Loading /> : 'Login'}</Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            ) : null}
            {!isAuthenticated ? (
                <Typography variant="body2" align="center" margin={2}>
                Forgot password? <Link to="/user/pwd_reset">Reset</Link>
                </Typography>
            ) : null}
        </Container>
    );
}

export default Login;