import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';



const initialState = {
    team: {},
    groups: {},
    isLoading: false
}


export const getTeam = createAsyncThunk(
    'team',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/user/team/');
            dispatch(setTeam(response.data));
            dispatch(getGroups(user));
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const deleteMember = createAsyncThunk(
    'member/delete',
    async (member, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.delete(`/user/team/${member.id}/`);
            dispatch(removeMember(member));
            toast.success('Member removed');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const createMember = createAsyncThunk(
    'member/add',
    async (member, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.post('/user/team/', member);
            dispatch(addMember(response.data));
            toast.success('Member added');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const updateMember = createAsyncThunk(
    'member/update',
    async (member, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.patch(`/user/team/${member.id}/`, member);
            dispatch(editMember(response.data));
            toast.success('Role updated');
            return response.data
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


export const getGroups = createAsyncThunk(
    'groups',
    async (user, {dispatch, rejectWithValue}) => {
        try {
            const response = await axios.get('/etc/groups/');
            dispatch(setGroups(response.data));
        } catch (error) {
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)


const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        setTeam (state, action) {
            state.team = action.payload;
        },
        removeMember (state, action) {
            const team = state.team;
            state.team = team.filter((member) => {return member.id !== action.payload.id});
        },
        addMember (state, action) {
            const team = state.team;
            state.team = [...team, action.payload]
        },
        editMember (state, action) {
            const team = state.team;
            state.team = team.map((member) => {
                if (member.id === action.payload.id) {
                    return {...member, ...action.payload};
                }
                return member;
            })
        },
        setGroups (state, action) {
            state.groups = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getTeam.pending, (state) => {
            state.isLoading = true;
        }).addCase(getTeam.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getTeam.rejected, (state) => {
            state.isLoading = false;
        }).addCase(getGroups.pending, (state) => {
            state.isLoading = true;
        }).addCase(getGroups.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(getGroups.rejected, (state) => {
            state.isLoading = false;
        }).addCase(deleteMember.pending, (state) => {
            state.isLoading = true;
        }).addCase(deleteMember.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(deleteMember.rejected, (state) => {
            state.isLoading = false;
        }).addCase(createMember.pending, (state) => {
            state.isLoading = true;
        }).addCase(createMember.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(createMember.rejected, (state) => {
            state.isLoading = false;
        }).addCase(updateMember.pending, (state) => {
            state.isLoading = true;
        }).addCase(updateMember.fulfilled, (state) => {
            state.isLoading = false;
        }).addCase(updateMember.rejected, (state) => {
            state.isLoading = false;
        })
    }
});

export const { setTeam, removeMember, addMember, editMember, setGroups } = teamSlice.actions;
export default teamSlice.reducer;
