import React, {useEffect, useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Typography, Paper, List, ListItem, ListItemText, IconButton, Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TextField, Grid, Autocomplete } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';

import Loading from '../../utils/Loading';
import { getTeam } from './TeamSlice';
import { deleteMember, createMember, updateMember } from './TeamSlice';

function Team () {
    const { isLoading, team, groups } = useSelector((state) => state.team);
    const { user } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [openDelete, setOpenDelete] = useState(false);
    const [memberToDelete, setMemberToDelete] = useState(null);
    const [openAdd, setOpenAdd] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [memberId, setMemberId] = useState('');
    const [userToEdit, setUserToEdit] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [group, setGroup] = useState(null);
    const memberToCreate = {
        user: {
            first_name: firstName,
            last_name: lastName,
            username: username,
            email: email
        },
        group: group
    };
    const memberToEdit = {
        id: memberId,
        user: userToEdit,
        group: group,
        owner: user
    }
    const groupChoices = groups.length > 0 ? groups.filter((group) => {
        return (group.name !== 'Partners');
    }) : []
    
    useEffect(() => {
        dispatch(getTeam(''));
    }, [dispatch])

    const handleEdit = (rowData) => {
        const memberList = team.filter((member) => {
            return (member.id === rowData.id);
        });
        if (memberList[0].group.name === 'Partners') {
            toast.info('Partner role cannot be changed');
        } else {
            const member = memberList[0];
            setMemberId(member.id);
            setUserToEdit(member.user);
            setGroup(member.group);
            setOpenEdit(true);
        }
    };
    const handleDelete = (rowData) => {
        const memberList = team.filter((member) => {
            return (member.id === rowData.id);
        });
        if (memberList[0].group.name === 'Partners') {
            toast.info('Partner role cannot be deleted');
        } else {
            setMemberToDelete(memberList[0]);
            setOpenDelete(true);
        }
    };
    const deleteConfirmed = () => {
        dispatch(deleteMember(memberToDelete));
        setOpenDelete(false);
    };

    const columns = [
        {field: 'firstName', headerName: 'First Name'},
        {field: 'lastName', headerName: 'Last Name'},
        {field: 'role', headerName: 'Role'},
        {field: 'team', headerName: 'Team'},
        {field: 'edit', headerName: 'Edit', type: 'actions', getActions: (params) => [
            <IconButton aria-label='Edit' onClick={() => handleEdit(params.row)}>
                <EditIcon />
            </IconButton>
        ]},
        {field: 'delete', headerName: 'Delete', type: 'actions', getActions: (params) => [
            <IconButton aria-label='Delete' onClick={() => handleDelete(params.row)}>
                <DeleteIcon />
            </IconButton>
        ]}
    ]
    const rows = team.length > 0 ? team.map((member) => (
            {id: member.id, firstName: member.user.first_name, lastName: member.user.last_name, role: member.group.name, team: member.owner.username}
        )) : []
    
    const getOptionLabel = (option) => {
        if (option === null) {
            return null
        }
        return option.name
    };
    const isOptionEqualToValue = (option, value) => {
        if (option === null || value === null) {
            return null;
        }
        return value.id === option.id;
    };
    const groupAutocomplete = () => {
        return (
            <Autocomplete
                value={group}
                onChange={(event, newValue) => setGroup(newValue)}
                options={groupChoices.length > 0 ? groupChoices : []}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                renderInput={(params) => <TextField {...params} label='Role' required />}
                sx={{padding: '18px'}}
            />
        )
    };
    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Team
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Button onClick={() => setOpenAdd(true)} type='button' endIcon={<AddIcon />}>Add member</Button>
            </Box>
            <Paper sx={{padding: 2}}>
                {isLoading || team.length === 0 ? (
                    <List>
                        <ListItem>
                            <ListItemText primary={isLoading ? 'Loading...' : 'No members'} />
                            {isLoading ? <Loading /> : null}
                        </ListItem>
                    </List>
                ) : (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        getRowId={(row) => row.id || Math.random().toString(36).substring(2, 15)}
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: {page: 0, pageSize: 5},
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                    />
                    )
                }
            </Paper>

            <Dialog open={openDelete} onClose={() => setOpenDelete(false)} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
                <DialogTitle id='alert-dialog-title'>
                    {'Remove member'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id='alert-dialog-description'>
                        Confirm deletion. Action cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
                    <Button onClick={deleteConfirmed} variant='contained' color='warning'>Confirm</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAdd} onClose={() =>  setOpenAdd(false)} PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    dispatch(createMember(memberToCreate));
                    setOpenAdd(false);
                },
            }}>
                <DialogTitle>Add member</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Submit member details. Member will be notified by email.
                    </DialogContentText>
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12} md={6}>
                            <TextField value={firstName} onChange={(e) => setFirstName(e.target.value)} label='First name' required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={lastName} onChange={(e) => setLastName(e.target.value)} label='Last name' required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={username} onChange={(e) => setUsername(e.target.value)} label='Username' required />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={email} onChange={(e) => setEmail(e.target.value)} label='Email' type='email' required />
                        </Grid>
                        <Grid item xs={12}>
                            {groupAutocomplete()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenAdd(false)} color='secondary'>Cancel</Button>
                    <Button type='submit' variant='contained'>Add</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEdit} onClose={() => setOpenEdit(false)} PaperProps={{
                component: 'form',
                onSubmit: (event) => {
                    event.preventDefault();
                    dispatch(updateMember(memberToEdit));
                    setOpenEdit(false);
                },
            }}>
                <DialogTitle>Change role</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Change role for team member {userToEdit !== null ? (`${userToEdit.first_name} ${userToEdit.last_name}`) : null}
                    </DialogContentText>
                    <Grid container spacing={2} align='center'>
                        <Grid item xs={12}>
                            {groupAutocomplete()}
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenEdit(false)} color='secondary'>Cancel</Button>
                    <Button type='submit' variant='contained'>Save</Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}

export default Team