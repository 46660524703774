import React, {useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Paper, Typography } from '@mui/material';
import { toast } from 'react-toastify';

import FleetForm from './FleetForm';
import { updateVehicle } from './FleetSlice';

function UpdateVehicle () {
    const { isLoading, fleet } = useSelector((state) => state.fleet);
    const { vehicleId } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fleetDetails = fleet.filter((vehicle) => {return (vehicle.id.toString() === vehicleId)})[0];
    const [registration, setRegistration] = useState(fleetDetails.registration);
    const [model, setModel] = useState(fleetDetails.model);
    const [location, setLocation] = useState(fleetDetails.location);
    const vehicle = {
        id: fleetDetails.id,
        registration: registration,
        model: model,
        location: location,
        owner: fleetDetails.owner
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (
            registration !== fleetDetails.registration ||
            model !== fleetDetails.model ||
            location !== fleetDetails.location
        ) {
            dispatch(updateVehicle(vehicle));
            navigate('/manage/fleet');
        } else {
            toast.info('No update');
        }
    }

    return (
        <Container>
            <Typography variant='h3' align='center' gutterBottom>
                Update car
            </Typography>
            <Paper sx={{padding: 2}}>
                <FleetForm 
                    action='update'
                    isLoading={isLoading}
                    vehicle={vehicle}
                    onRegistrationChange={setRegistration}
                    onModelChange={setModel}
                    onLocationChange={setLocation}
                    handleSubmit={handleSubmit}
                />
            </Paper>
        </Container>
    )
}

export default UpdateVehicle