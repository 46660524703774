import React from "react";
import { Container, Grid, Typography, Card, CardMedia, CardContent, CardActions, Button } from '@mui/material'
import { Link } from "react-router-dom"
import MainMenu from "./menu/MainMenu";


function Home () {
    return (
        <Container>
            <MainMenu />
            <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: 8 }}>
                <Grid item xs={12}>
                    <Card sx={{maxWidth: 345}} style={{margin: 'auto'}}>
                        <CardMedia sx={{height: 140}} image="/logo_blue.png" title="logo" />
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                                Rental Management
                            </Typography>
                            <Typography variant="body2" style={{marginBottom: 2}} color="text.secondary">
                                This portal is exclusively available to our partners.
                            </Typography>
                            <Typography variant="body2" style={{marginBottom: 2}} color="text.secondary">
                                Conveniently promote and manage your rental cars with WST Car Hire Ltd.
                            </Typography>
                            <Typography variant="body2" style={{marginBottom: 2}} color="text.secondary">
                                Register today to get started and partner with us.
                            </Typography>
                        </CardContent>
                        <CardActions sx={{display: 'flex', justifyContent: 'space-between'}}>
                            <Link to={'/login'}>
                                <Button size="small">Login</Button>
                            </Link>
                            <Link to={'/signup'}>
                                <Button size="small">Register</Button>
                            </Link>
                        </CardActions>
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Home;