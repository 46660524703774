import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container, Grid, Typography, Card, CardContent, CardActions, Button, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';

import MainMenu from '../menu/MainMenu';
import Loading from '../../utils/Loading';
import { resetPassword } from './AccountSlice';


export default function PwdReset () {
    const dispatch = useDispatch();
    const { isLoading, pwdResetSent } = useSelector((state) => state.account);
    const [email, setEmail] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (email !== '') {
            const emailData = {
                email: email,
            }
            dispatch(resetPassword(emailData));
        } else {
            toast.error('No email address provided');
        }
    };

    return (
        <Container>
            <MainMenu />
            <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={12}>
                    <form onSubmit={handleSubmit}>
                        <Card sx={{maxWidth: 375}} style={{margin: 'auto'}}>
                            {!pwdResetSent ? (
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Password reset
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Submit your registered email address to reset your password.
                                    </Typography>
                                    <TextField label='Email address' required type='email' onChange={(e) => setEmail(e.target.value)} />
                                </CardContent>
                            ) : (
                                <CardContent>
                                    <Typography gutterBottom variant='h5' component='div'>
                                        Email sent
                                    </Typography>
                                    <Typography variant='body2' color='text.secondary'>
                                        Check your email. We have sent you a link to reset your password.
                                    </Typography>
                                </CardContent>
                            )}
                            <CardActions>
                                <Button type='submit' disabled={pwdResetSent} variant='contained' endIcon={<SendIcon />}>
                                    {isLoading ? <Loading /> : 'Send email'}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                </Grid>
            </Grid>
        </Container>
    )
}