import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Container, Box, Button, Tooltip, IconButton, Avatar } from '@mui/material';

import AccountMenu from './AccountMenu';


export default function MainMenu() {
    const { user } = useSelector((state) => state.auth);
    const avatarButton = useRef()
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = () => {
        setAnchorEl(avatarButton.current);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const loggedinUser = user && user.username ? user.username : "Anonymous";

    return (
        <Container sx={{display: 'flex', justifyContent: 'space-between'}}>
            <Box sx={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                <Link to={'/'}>
                    <img src='/logo_blue.png' alt='logo' height={75}></img>
                </Link>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
                <Link to={'/dashboard'}>
                    <Button sx={{marginRight: '25px'}}>Dashboard</Button>
                </Link>
                <Tooltip title="Account">
                    <IconButton ref={avatarButton} onClick={handleClick} size="small" aria-controls={anchorEl ? 'account-menu' :  undefined} aria-haspopup="true" aria-expanded={anchorEl ? 'true' : undefined}>
                        <Avatar sx={{width: 32, height: 32}}>{loggedinUser[0].toUpperCase()}</Avatar>
                    </IconButton>
                </Tooltip>
                <AccountMenu loggedUser={loggedinUser} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose} /> 
            </Box>
        </Container>
    )
}