import React from 'react';
import { FormControl, InputLabel, Select, OutlinedInput, Box, Chip, MenuItem } from '@mui/material';


function MultiSelect ({inputLabel, values, valuesState, labelFunction, onChange}) {
    const idValue = inputLabel.replace(' ', '');

    return (
        <FormControl sx={{ m: 1, width: 200}}>
            <InputLabel id={`id-${idValue}`}>{inputLabel}</InputLabel>
            <Select
                labelId={`id=${idValue}`}
                id={idValue}
                multiple
                value={values.filter((value) => valuesState.some(item => item.id === value.id))}
                onChange={onChange}
                input={<OutlinedInput id={`select-${idValue}`} label='Chip' />}
                renderValue={(selected) => (
                    <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                        {selected.map((value) => (
                            <Chip key={value.id} label={labelFunction(value)} />
                        ))}
                    </Box>
                )}                                    
            >
                {Object.keys(values).length > 0 ? (values.map((value) => (
                    <MenuItem
                        key={value.id}
                        value={value}
                        selected={valuesState.some(item => item.id === value.id)}
                    >
                        {labelFunction(value)}
                    </MenuItem>
                ))) : (null)}
            </Select>
        </FormControl>
    )
}

export default MultiSelect;