import React from "react";
import axios from "axios";
import moment from "moment";
import 'moment-timezone';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentUser, setToken } from "./components/login/LoginSlice";
import { isEmpty } from "./utils/Utils";
import AuthComponent from "./utils/AuthComponent";
import ErrorPage from "./utils/ErrorPage";
import Home from "./components/Home";
import Signup from "./components/signup/Signup";
import Login from "./components/login/Login";
import Account from "./components/account/Account";
import Profile from "./components/account/Profile";
import PwdReset from './components/account/PwdReset';
import PwdResetConfirm from './components/account/PwdResetConfirm';
import Dashboard from "./components/dashboard/Dashboard";
import Manage from "./components/dashboard/Manage";
import Fleet from "./components/fleet/Fleet";
import UpdateVehicle from "./components/fleet/UpdateVehicle";
import CreateVehicle from "./components/fleet/CreateVehicle";
import Team from "./components/team/Team";
import Operations from "./components/operations/Operations";
import UpdateOps from "./components/operations/UpdateOps";
import Bookings from "./components/bookings/Bookings";
import BookingDetail from "./components/bookings/BookingDetail";
import BookingUpdate from "./components/bookings/BookingUpdate";
import BookingGallery from "./components/bookings/BookingGallery";
import Discounts from "./components/discounts/Discounts";
import Rates from "./components/rates/Rates";
import PartnerRates from "./components/rates/PartnerRates";
import Planner from "./components/planner/Planner";


axios.defaults.baseURL = "https://bookings.carhiremauritius.com/en/api/v1";
moment.tz.setDefault('UTC');

function App () {
  //Check if user credentials in browser to avoid new login after page refresh
  const dispatch = useDispatch();
  if (!isEmpty(localStorage.getItem("token"))) {
    dispatch(setToken(localStorage.getItem("token")));
  }
  if (!isEmpty(localStorage.getItem("user"))) {
    const user = JSON.parse(localStorage.getItem("user"));
    dispatch(setCurrentUser(user, ""));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Routes>
        <Route exact path="/" element={<Home />} errorElement={<ErrorPage />} />
        <Route path="/signup" element={<Signup />} errorElement={<ErrorPage />} />
        <Route path="/login" element={<Login />} errorElement={<ErrorPage />} />
        <Route path="/user/account" element={<AuthComponent Component={Account} />} errorElement={<ErrorPage />} />
        <Route path="/user/profile" element={<AuthComponent Component={Profile} />} errorElement={<ErrorPage />} />
        <Route path='/user/pwd_reset' element={<PwdReset />} errorElement={<ErrorPage />} />
        <Route path='/user/confirm_pwd_reset/:uid/:token' element={<PwdResetConfirm />} errorElement={<ErrorPage />} />
        <Route path="/dashboard" element={<AuthComponent Component={Dashboard} />} errorElement={<ErrorPage />} />
        <Route path="/manage" element={ <AuthComponent Component={Manage} />} errorElement={<ErrorPage />}>
          <Route path="fleet" element={<Fleet />} errorElement={<ErrorPage />} />
          <Route path='fleet/:vehicleId/update' element={<UpdateVehicle />} errorElement={<ErrorPage />} />
          <Route path='fleet/create' element={<CreateVehicle />} errorElement={<ErrorPage />} />
          <Route path='team' element={<Team />} errorElement={<ErrorPage />} />
          <Route path='ops' element={<Operations />} errorElement={<ErrorPage />} />
          <Route path='ops/:opId/update' element={<UpdateOps />} errorElement={<ErrorPage />} />
          <Route path='bookings' element={<Bookings />} errorElement={<ErrorPage />} />
          <Route path='bookings/:bookingId/details' element={<BookingDetail />} errorElement={<ErrorPage />} />
          <Route path='bookings/:sourceComponent/:bookingRef/update' element={<BookingUpdate />} errorElement={<ErrorPage />} />
          <Route path='bookings/:bookingRef/gallery' element={<BookingGallery />} errorElement={<ErrorPage />} />
          <Route path='bookings/:bookingRef/discounts' element={<Discounts />} errorElement={<ErrorPage />} />
          <Route path='partner/rates' element={<PartnerRates />} errorElement={<ErrorPage />} />
          <Route path='admin/rates' element={<Rates />} errorElement={<ErrorPage />} />
          <Route path='planner' element={<Planner />} errorElement={<ErrorPage />} />
        </Route>
      </Routes>
      <ToastContainer hideProgressBar={true} newestOnTop={true} autoClose={6000} />
    </LocalizationProvider>
  );
}

export default App;
