import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem, Divider, Typography, Avatar } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { Login, Logout, AppRegistration } from "@mui/icons-material";

import Loading from "../../utils/Loading";
import { toastOnError } from "../../utils/Utils";
import { logout } from "../login/LoginSlice";


function AccountMenu(props) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isAuthenticated, isLoading } = useSelector((state) => state.auth);
    const onLogout = () => {
        try {
            dispatch(logout(user));
        } catch (error) {
            toastOnError(error);
        }
    };
    const onLogin = () => {
        navigate('/login');
    };
    const onRegister = () => {
        navigate('/signup');
    };
    const onAccount = () => {
        navigate('/user/account');
    };
    const onProfile = () => {
        navigate('/user/profile');
    }

    return (
        <Menu id="account-menu" anchorEl={props.anchorEl} open={props.open} onClose={props.onClose} transformOrigin={{horizontal: 'right', vertical: 'top'}} anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
            <MenuItem>
                <Typography variant="subtitle2" align="center">
                    {props.loggedUser} 
                </Typography>
            </MenuItem>
            <Divider />
            { isAuthenticated ? (
                <div>
                    <MenuItem onClick={onAccount}>
                        <Avatar sx={{width: 32, height: 32, marginRight: 1}}>{props.loggedUser[0].toUpperCase()}</Avatar> Account
                    </MenuItem>
                    <MenuItem onClick={onProfile}>
                        <Avatar sx={{width: 32, height: 32, marginRight: 1}}>{props.loggedUser[0].toUpperCase()}</Avatar> Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={onLogout}>
                        <ListItemIcon>
                            <Logout fontSize="small" />
                        </ListItemIcon>
                        {isLoading ? <Loading /> : 'Logout'}
                    </MenuItem>
                </div>
            ) : (
                <div>
                    <MenuItem onClick={onLogin}>
                        <ListItemIcon>
                            <Login fontSize="small" />
                        </ListItemIcon>
                        Login
                    </MenuItem>
                    <Divider />
                    <MenuItem onClick={onRegister}>
                        <ListItemIcon>
                            <AppRegistration fontSize="small" />
                        </ListItemIcon>
                        Register
                    </MenuItem>
                </div>
            )}  
        </Menu>
    );
}

export default AccountMenu;
