import axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'redux-first-history';
import { toast } from 'react-toastify';

import { toastOnError } from '../../utils/Utils';


const initialState ={
    username: '',
    usernameError: '',
    passwordError: '',
    emailError: '',
    isCreated: false,
    isLoading: false
}

export const createNewUser = createAsyncThunk(
    'create/user',
    async (userData, { dispatch, rejectWithValue }) => {
        try {
            const response = await axios.post('/auth/users/', userData);
            dispatch(createUserSuccess(response.data));
            dispatch(push('/dashboard'));
            toast.success(`Account for ${userData.username} created. Please login`);
            return response.data
        } catch (error) {
            dispatch(createUserError(error.response.data));
            toastOnError(error);
            return rejectWithValue(error.response.data);
        }
    }
)

const signupSlice = createSlice({
    name: 'signup',
    initialState,
    reducers: {
        createUserSuccess (state, action) {
            state.username = action.payload.username;
        },
        createUserError (state, action) {
            if (action.payload.hasOwnProperty('username')) {
                state.usernameError = action.payload['username'];
            };
            if (action.payload.hasOwnProperty('password')) {
                state.passwordError = action.payload['password'];
            };
            if (action.payload.hasOwnProperty('email')) {
                state.emailError = action.payload['email'];
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createNewUser.pending, (state) => {
            state.isLoading = true;
            state.isCreated = false;
        }).addCase(createNewUser.fulfilled, (state) => {
            state.isCreated = true;
            state.isLoading = false;
        }).addCase(createNewUser.rejected, (state, action) => {
            state.isLoading = false;
            state.isCreated = false;
            toastOnError(action.error);
        });
    },
});

export const { createUserSuccess, createUserError } = signupSlice.actions;
export default signupSlice.reducer;